import React from 'react';
import { rupeeDecimalFormatter } from '../../../utils/utility';

const repaymentColumns = [
  {},
  {},
  {
    Header: 'Transaction Description',
    accessor: 'transactionDescription',
    width: 200
  },
  {
    Header: 'Invoice No.',
    Cell: ({ original }) => {
      let { invoiceNumber } = original;
      return (
        <>
          {invoiceNumber?.map((e, index) => {
            return (
              <div className="invoice_capsule" key={`invoice-${index}`}>
                {e}
              </div>
            );
          })}
        </>
      );
    }
  },
  {},
  {
    Header: 'Credit',
    accessor: 'creditAmount',
    headerStyle: { textAlign: 'right' },
    Cell: props => {
      return (
        <div className="right-aligned">
          {rupeeDecimalFormatter(props?.value, '₹')}
        </div>
      );
    }
  },
  {
    Header: 'Balance',
    accessor: 'outstandingBalance',
    headerStyle: { textAlign: 'right' },
    Cell: props => {
      return (
        <div className="right-aligned">
          {rupeeDecimalFormatter(props?.value, '₹')}
        </div>
      );
    }
  },
  { width: 60 },
  {
    Header: ' ',
    accessor: '',
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return;
    }
  }
];

export { repaymentColumns };

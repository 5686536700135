import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { repaymentColumns } from './interestAndCharges.config.js';
import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';
import CredoModal from '../../../components/CredoModal';
import ManageCharges from '../../../components/Modals/ManageCharges.js';
import { connect } from 'react-redux';
import { getSoa, getSoaSummary } from './interestAndCharges.actions';
import { bindActionCreators } from 'redux';
import ExcelIcon from '../../../assets/images/excel.svg';
import PdfIcon from '../../../assets/images/pdf.svg';
import WaiveOffModal from './waiveOffModal.js';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import Wavier from '../../../assets/images/wavier.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrowMark from '../../../assets/images/up_arrow.svg';
import DownArrowMark from '../../../assets/images/down_arrow.svg';
import { loaderModal } from '../../../store/actions';
import { API_GET_EXPORT_SOA_EXCEL } from '../../../utils/APIUrls';
import axios from 'axios';
import FileDownload from 'js-file-download';
import InvoiceInterestDetails from './InvoiceInterestDetails.js';

class InterestAndCharges extends Component {
  state = {
    isModalOpen: false,
    perPage: 10,
    totalPages: 0,
    pageOffSet: 0,
    currentPage: 1,
    sortTransactionDate: -1,
    sortEffectiveDate: -1,
    sortingOn: 'effectiveDate'
  };

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { perPage, pageOffSet } = this.state,
      { userId } = match.params,
      { getSoaSummary, getSoa } = actions,
      token = localStorage.getItem('token');
    actions.loaderModal(true);
    if (token) {
      await getSoa(userId);
      getSoaSummary(userId);
      actions.loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }

    let slicedSoaData = this.props.soaData?.slice(
      pageOffSet,
      pageOffSet + perPage
    );
    this.setState({
      totalPages: Math.ceil(this.props.soaData?.length / perPage),
      slicedSoaData
    });
  };

  getPageData = type => {
    let { currentPage, totalPages, perPage, pageOffSet } = this.state,
      { soaData } = this.props,
      slicedSoaData;
    if (type === 'prev' && currentPage > 1) {
      currentPage = currentPage - 1;
      pageOffSet = pageOffSet - 10;
      slicedSoaData = soaData.slice(pageOffSet, pageOffSet + perPage);
      this.setState({
        currentPage,
        pageOffSet,
        slicedSoaData
      });
    } else if (type === 'next' && currentPage < totalPages) {
      pageOffSet = currentPage * perPage;
      slicedSoaData = soaData.slice(pageOffSet, pageOffSet + perPage);
      this.setState({
        currentPage: currentPage + 1,
        pageOffSet,
        slicedSoaData
      });
    }
  };

  getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          backgroundColor:
            rowInfo.original.repayDetails !== null ? '#057365' : '',
          color: rowInfo.original.repayDetails !== null ? 'white' : 'black'
        }
      };
    }
    return {};
  };
  getTheadThProps = (state, rowInfo, column, instance) => ({
    onClick: async () => {
      let { sortTransactionDate, sortEffectiveDate } = this.state,
        { actions, match } = this.props,
        { userId } = match.params;
      if (column?.id === 'effectiveDate') {
        await this.setState({
          sortEffectiveDate: sortEffectiveDate === -1 ? 1 : -1,
          sortTransactionDate: -1,
          sortingOn: 'effectiveDate',
          perPage: 10,
          totalPages: 0,
          pageOffSet: 0,
          currentPage: 1
        });
      } else if (column?.id === 'transactionDate') {
        await this.setState({
          sortTransactionDate: sortTransactionDate === -1 ? 1 : -1,
          sortEffectiveDate: -1,
          sortingOn: 'transactionDate',
          perPage: 10,
          totalPages: 0,
          pageOffSet: 0,
          currentPage: 1
        });
      }
      actions.loaderModal(true);
      await actions.getSoa(
        userId,
        this.state.sortingOn === 'transactionDate'
          ? this.state.sortTransactionDate
          : this.state.sortEffectiveDate,
        this.state.sortingOn
      );

      let slicedSoaData = this.props.soaData?.slice(
        this.state.pageOffSet,
        this.state.pageOffSet + this.state.perPage
      );
      this.setState({
        totalPages: Math.ceil(this.props.soaData?.length / this.state.perPage),
        slicedSoaData
      });
      actions.loaderModal(false);
    }
  });

  toggleModal = (modalType, rowData, modalName) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      rowData
    });

    if (modalName === 'ManageCharges' && this.state.isModalOpen === true) {
      let { actions, match } = this.props,
        { userId } = match.params,
        { getSoaSummary, getSoa } = actions;

      getSoa(userId);
      getSoaSummary(userId);
    }
  };

  soaDownload = async () => {
    let { sortingOn, sortTransactionDate, sortEffectiveDate } = this.state,
      sortParameter =
        sortingOn === 'transactionDate'
          ? sortTransactionDate
          : sortEffectiveDate;
    const token = localStorage.getItem('token'),
      { match, actions } = this.props,
      { userId } = match.params,
      headers = {
        'X-CLIENT-NAME': 'PDU',
        'X-USER-ID': localStorage.getItem('userId'),
        Authorization: `Bearer ${token}`
      };
    actions.loaderModal(true);

    axios({
      url: `${API_GET_EXPORT_SOA_EXCEL}?userId=${userId}&sortParameter=${sortParameter}&sortingOn=${sortingOn}`,
      method: 'GET',
      headers: headers,
      responseType: 'blob' // Important
    }).then(res => {
      actions.loaderModal(false);

      FileDownload(res.data, `soa_excel_${userId}.xlsx`);
    });
  };

  render() {
    let {
        isModalOpen,
        modalType,
        rowData,
        totalPages,
        currentPage,
        slicedSoaData,
        sortEffectiveDate,
        sortTransactionDate
      } = this.state,
      { soaSummary } = this.props,
      {
        charges,
        discount,
        grossOutstanding,
        interest,
        minAmountDue,
        netOutstanding,
        pendingInvoices,
        principal
      } = soaSummary;

    const applicationColumns = [
      {
        Header: () => (
          <span>
            Effective Date
            <img
              src={sortEffectiveDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'effectiveDate'
      },

      {
        Header: () => (
          <span>
            Transaction Date
            <img
              src={sortTransactionDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'transactionDate'
      },
      {
        Header: 'Transaction Description',
        accessor: 'transactionDescription',
        width: 200
      },
      {
        Header: 'Invoice No.',
        accessor: 'invoiceNumber',
        Cell: ({ original }) => {
          let { invoiceNumber } = original;
          return (
            <>
              {invoiceNumber ? (
                <div
                  className='invoice_number'
                  onClick={() => {
                    this.toggleModal(InvoiceInterestDetails, original);
                  }}
                >
                  {invoiceNumber}
                </div>
              ) : null}
            </>
          );
        }
      },
      {
        Header: 'Debit',
        accessor: 'debitAmount',
        headerStyle: { textAlign: 'right' },
        Cell: props => {
          return (
            <div className='right-aligned'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        }
      },
      {
        Header: 'Credit',
        accessor: 'creditAmount',
        headerStyle: { textAlign: 'right' },
        Cell: props => {
          return (
            <div className='right-aligned'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        }
      },
      {
        Header: 'Balance',
        accessor: 'outstandingBalance',
        headerStyle: { textAlign: 'right' },
        Cell: props => {
          return (
            <div className='right-aligned'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        }
      },
      {
        Header: 'Waiver',
        headerStyle: { textAlign: 'center' },
        width: 60,
        Cell: ({ original }) => {
          let { status, transactionDescription } = original;
          return (
            <div style={{ textAlign: 'center' }}>
              {status === 'pending' &&
              (transactionDescription === 'Due: Interest' ||
                transactionDescription === 'charge') ? (
                <img
                  src={Wavier}
                  alt='icon'
                  className='wavier_img'
                  onClick={() => {
                    this.toggleModal(WaiveOffModal, original);
                  }}
                />
              ) : null}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: '',
        expander: true,
        Expander: ({ isExpanded, ...row }) => {
          if (row.original.repayDetails !== null) {
            return (
              <img
                alt='icon'
                style={{ height: '8px' }}
                src={isExpanded ? UpArrowMark : DownArrowMark}
              />
            );
          }
        }
      }
    ];
    return (
      <>
        <div className='interest_charges_wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Interest and Charges</title>
          </Helmet>
          <div className='row'>
            <div className='col-md-9'>
              <div className='card m-r-2'>
                <h3 className='card_heading d-flex justify-content-between'>
                  <p>Summary</p>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      this.toggleModal(ManageCharges);
                    }}
                  >
                    Manage Charges
                  </Button>
                </h3>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-2 text-grey_small'>
                        Pending Invoices
                        <div className='text-bold'>{pendingInvoices}</div>
                      </div>
                      <div className='col-md-2 text-grey_small'>
                        Principal
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(principal, '₹')}
                        </div>
                      </div>
                      <div className='col-md-2 text-grey_small'>
                        Interest
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(interest, '₹')}
                        </div>
                      </div>
                      <div className='col-md-2 text-grey_small'>
                        Charges
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(charges, '₹')}
                        </div>
                      </div>
                      <div className='col-md-2 text-grey_small'>
                        Gross Outstanding
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(grossOutstanding, '₹')}
                        </div>
                      </div>
                      <div className='col-md-2 text-grey_small'>
                        Discount
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(discount, '₹')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 p-left'>
              <div className='row card_secondary card_secondary_orange'>
                <div className='col-md-12'>Minimum amount due</div>
                <div className='col-md-12 card_secondary_value'>
                  {rupeeDecimalFormatter(minAmountDue, '₹')}
                </div>
              </div>
              <div className='row card_secondary card_secondary_green'>
                <div className='col-md-12'>Net Outstanding</div>
                <div className='col-md-12 card_secondary_value'>
                  {rupeeDecimalFormatter(netOutstanding, '₹')}
                </div>
              </div>
            </div>
          </div>

          <div className='card m-t-0'>
            <div className='row'>
              <h3 className='col-md-10 card_heading_secondary'>
                SOA
                <a onClick={this.soaDownload} download>
                  <img src={ExcelIcon} alt='icon' className='icon_image' />
                </a>
                <img src={PdfIcon} alt='icon' className='icon_image' />
              </h3>
              <h5 className='col-md-2  card_heading_secondary_right'>
                All amounts in ₹
              </h5>
            </div>
            <ReactTable
              data={slicedSoaData}
              columns={applicationColumns}
              minRows={10}
              showPagination={false}
              getTrProps={this.getTrProps}
              sortable={false}
              getTheadThProps={this.getTheadThProps}
              SubComponent={row => {
                return (
                  <div className='repayment_table_wrapper'>
                    {row.original.repayDetails ? (
                      <ReactTable
                        data={row.original.repayDetails}
                        columns={
                          row.original.repayDetails ? repaymentColumns : []
                        }
                        minRows={0}
                        showPagination={false}
                      />
                    ) : null}
                  </div>
                );
              }}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev')}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {currentPage} of {totalPages}
                </span>
              </li>
              <li>
                <button
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next')}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>

        <CredoModal
          isOpen={isModalOpen}
          RenderingComponent={modalType}
          title={
            modalType?.WrappedComponent?.name === 'ManageCharges'
              ? 'Manage Charges'
              : null
          }
          styles={
            modalType?.WrappedComponent?.name === 'ManageCharges' ||
            'InvoiceInterestDetails'
              ? {
                  content: {
                    width: '85vw',
                    height: '85vh'
                  }
                }
              : {
                  content: {
                    width: '60vw',
                    height: '60vh'
                  }
                }
          }
          // closeAction={() => this.toggleModal('')}
          rowData={rowData}
          closeAction={() => {
            modalType === ManageCharges
              ? this.toggleModal(modalType, '', 'ManageCharges')
              : this.toggleModal(modalType, '', '');
          }}
          toggleModal={this.toggleModal}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  interestAndChargesReducer,
  leadManagementNavsReducer
}) => ({
  soaData: interestAndChargesReducer.soaData,
  promoterData: leadManagementNavsReducer.promoterData,
  soaSummary: interestAndChargesReducer.soaSummary
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getSoa, getSoaSummary, loaderModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InterestAndCharges);

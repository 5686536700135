import React, { Component } from 'react';
import Logo from '../../assets/images/logo.png';
import Phone from '../../assets/images/phoneImage.png';
import { API_POST_LOGIN } from '../../utils/APIUrls';
import { Helmet } from 'react-helmet';
import axios from 'axios';
export default class Login extends Component {
  state = {
    email: '',
    password: ''
  };

  submit = e => {
    e.preventDefault();
    let { history } = this.props,
      { email, password } = this.state,
      datasend = {
        email: email,
        password: password
      };

    axios
      .post(`${API_POST_LOGIN}`, datasend)
      .then(({ data }) => {
        if (data.code === 200) {
          localStorage.setItem('token', data?.data?.token);
          localStorage.setItem('userId', data?.data?.userId);
          history.push('/pay-app/dashboard');
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else alert('Something went wrong');
      })
      .catch(err => {
        throw err;
      });
  };
  render() {
    let { email, password } = this.state;
    return (
      <div className='login-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Pemant</title>
        </Helmet>
        <div className=' row nav-bar container'>
          <img src={Logo} alt='company logo' className='logo_image' />
        </div>
        <div className='login-container'>
          <div className='login-container_left container'>
            <div className='login'>
              <h2>Welcome to</h2>
              <h2> Pemant Admin Dashboard</h2>
              <form onSubmit={e => this.submit(e)}>
                <div className='form-input'>
                  <input
                    placeholder=' Enter Login Id or Mobile Number'
                    value={email}
                    autoComplete='false'
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <input
                    placeholder='Enter Password'
                    className='mt-3'
                    value={password}
                    autoComplete='false'
                    type='password'
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </div>

                <button type='submit'>Login</button>
              </form>
            </div>
            <div className='login-container_right'>
              <img src={Phone} alt='phone_image' className='phone_image' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import Dashboard from '../src/views/LeadManagement/Dashboard/Dashboard';
import Login from '../src/views/Login/Login';
import { Route, Switch, Router } from 'react-router-dom';
import LeadManager from '../src/views/LeadManagement/index';
import { createBrowserHistory } from 'history';
import CreditBehaviour from './views/LeadManagement/Application/CreditBehaviour/CreditBehaviour';
import { loaderModal } from './store/actions';
import LoaderModal from './components/LoaderModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EodManagement from './views/EodManagement/EodManagement';
export const hist = createBrowserHistory();

class App extends Component {
  render() {
    let { loaderModalStatus } = this.props;
    return (
      <Router history={hist}>
        <LoaderModal status={loaderModalStatus} />
        <Switch>
          <Route path="/pay-app/dashboard" exact component={Dashboard} />
          <Route
            path="/pay-app/:loanId/:userId/creditBehaviour"
            exact
            component={CreditBehaviour}
          />
          <Route path="/" exact component={Login} />
          <Route path={'/pay-app/:loanId/:userId'} component={LeadManager} />
          <Route path="/pay-app/eod" exact component={EodManagement} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

const setMaxDate = (type = '') => {
  let dateArr = new Date().toLocaleDateString().split('/');
  let date = dateArr[1];
  let month = dateArr[0];
  let year = dateArr[2];
  if (type === 'dob') {
    year = Number(year) - 18;
  } else {
    date = Number(date) - 1;
  }
  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${year}-${month}-${date}`;
};

const getDMY = dateStr => {
  let dateObj = new Date(dateStr),
    dd = dateObj.getDate(),
    mm = dateObj.getMonth() + 1,
    year = dateObj.getFullYear(),
    month = mm < 10 ? `0${mm}` : `${mm}`,
    date = dd < 10 ? `0${dd}` : `${dd}`;
  return `${date}/${month}/${year}`;
};

const rupeeCommaFormatter = value => {
  let val = value && Math.round(value).toString();
  if (!val) {
    return '0';
  }
  if (val.length > 3) {
    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  } else {
    return val;
  }
};

const numericInput = (value, callback) => {
  let reg = /^[0-9]*$/;
  if (reg.test(value)) {
    callback(value);
  }
};
const todayDate = () => {
  var today = new Date();
  var current_date = today.getDate();
  var current_month = today.getMonth() + 1;
  var current_year = today.getFullYear();
  return current_date + '/' + current_month + '/' + current_year;
};

const rupeeDecimalFormatter = (value, sign = '') => {
  if (value === undefined || value === null) {
    return '-';
  } else {
    var x = value && value.toString();
    if (!x) {
      return `${sign}   0`;
    }
    var afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      lastThree +
      afterPoint;
    return `${sign}   ${res}`;
  }
};

export {
  setMaxDate,
  getDMY,
  rupeeCommaFormatter,
  numericInput,
  todayDate,
  rupeeDecimalFormatter
};

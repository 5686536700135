import React, { Component } from 'react';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class OfferHistoryModal extends Component {
  render() {
    let { propsData } = this.props,
      { offerHistory, match, promoterData } = propsData,
      { userId } = match.params;
    return (
      <div className="offer_wrapper">
        <h3 className="modal_title">Offer History</h3>
        {offerHistory?.map((e, index) => {
          return (
            <div className="modal_card " key={`offer-${index}`}>
              <h3 className="modal_card-heading ">Offer Details-{index + 1}</h3>

              <div className="row main-row border-grey ">
                <div className="col-md-3 main-row_inner">
                  Entity Name
                  <div className="text-bold">
                    {promoterData?.entityName || '-'}
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  User ID
                  <div className="text-bold">{userId || '-'}</div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Offer ID
                  <div className="text-bold ">
                    {e?.offerId || '-'}
                    {e.isActive === true ? (
                      <span className=" offer-history_active px-4 mx-2">
                        Active
                      </span>
                    ) : (
                      <span className="  offer-history_expired px-4 mx-2">
                        Expired
                      </span>
                    )}{' '}
                  </div>
                </div>
              </div>

              <div className="row main-row border-grey ">
                <div className="col-md-3 main-row_inner">
                  Product Scheme
                  <div className="text-bold">{e?.prodName || '-'}</div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Program
                  <div className="text-bold">{e?.programName || '-'}</div>
                </div>
              </div>

              <div className="row main-row">
                <div className="col-md-3 main-row_inner">
                  Santion Limit
                  <div className="text-bold">
                    ₹ {rupeeDecimalFormatter(e?.offerLimitAmt)}
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Created on
                  <div className="text-bold">{e?.sanctionDate || '-'}</div>
                </div>
              </div>

              <div className="row main-row border-grey ">
                <div className="col-md-3 main-row_inner">
                  Standard tenure
                  <div className="text-bold">
                    {rupeeDecimalFormatter(e?.tenure)} Days
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Extension
                  <div className="text-bold">
                    {rupeeDecimalFormatter(e?.extension)} Days
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Grace Period
                  <div className="text-bold">
                    {rupeeDecimalFormatter(e?.gracePeriod)} Days
                  </div>
                </div>
              </div>

              <div className="row main-row">
                <div className="col-md-3 main-row_inner">
                  Monthly Interest Rate
                  <div className="text-bold">
                    {rupeeDecimalFormatter(e?.monthlyInterestRate)} %
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Extension Interest Rate
                  <div className="text-bold">
                    {rupeeDecimalFormatter(e?.extensionInterestRate)} %
                  </div>
                </div>
                <div className="col-md-3 main-row_inner">
                  Setup Fees
                  <div className="text-bold">
                    ₹ {rupeeDecimalFormatter(e?.setupFees)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

import React, { Component } from 'react';
import ReactTable from 'react-table';
import { API_GET_SUPPLIER_PAYMENT_DETAILS } from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';

export default class SupplierDetailsModal extends Component {
  state = {
    activeFigure: 'upi',
    bankList: [],
    upiList: []
  };
  componentDidMount = async () => {
    let { stateData } = this.props,
      { rowData } = stateData;
    GET(API_GET_SUPPLIER_PAYMENT_DETAILS(rowData?.uniqueId))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({
            bankList: data?.data?.bankList,
            upiList: data?.data?.upiList
          });
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  };

  paymentHandler = type => {
    this.setState({ activeFigure: type });
  };

  render() {
    const bankColumns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Account No.',
        accessor: 'accountNumber'
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifscCode'
      },

      {
        Header: 'Bank Name',
        accessor: 'bankName'
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName'
      },
      {
        Header: 'Verified',
        accessor: 'verified',
        Cell: ({ original }) => {
          let { verified } = original;
          return (
            <>
              {verified === 'Yes' ? (
                <span className='capsule capsule-green'>Yes</span>
              ) : (
                <span className='capsule capsule-red'>No</span>
              )}
            </>
          );
        }
      }
    ];

    const upiColumns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'UPI ID',
        accessor: 'upiId'
      },
      {
        Header: 'Verified',
        accessor: 'verified',
        Cell: ({ original }) => {
          let { verified } = original;
          return (
            <>
              {verified === 'Yes' ? (
                <span className='capsule capsule-green'>Yes</span>
              ) : (
                <span className='capsule capsule-red'>No</span>
              )}
            </>
          );
        }
      }
    ];
    let { activeFigure, bankList, upiList } = this.state,
      { stateData } = this.props,
      { rowData } = stateData;
    return (
      <div className='modal-wrapper manage_user-wrapper'>
        <h3 className='card_heading'>Payment Details </h3>
        <div className='row'>
          <div className='col-md-3 text-grey'>
            Supplier Name{' '}
            <div className='text-bold'>{rowData?.name || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Supplier ID{' '}
            <div className='text-bold'>{rowData?.uniqueId || '-'}</div>
          </div>
        </div>
        <div className='row  generic_button_box'>
          <div
            onClick={() => this.paymentHandler('upi')}
            className={
              activeFigure === 'upi'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            UPI
          </div>
          <div
            onClick={() => this.paymentHandler('bank')}
            className={
              activeFigure === 'bank'
                ? 'col-md-1 generic_button_active'
                : 'col-md-1 generic_button'
            }
          >
            Bank
          </div>
        </div>
        <h3 className='card_heading mt-4'>Payments</h3>
        {activeFigure === 'upi' ? (
          <ReactTable
            columns={upiColumns}
            data={upiList || []}
            defaultPageSize={10}
            showPagination={false}
          />
        ) : (
          <ReactTable
            columns={bankColumns}
            data={bankList || []}
            defaultPageSize={10}
            showPagination={false}
          />
        )}
      </div>
    );
  }
}

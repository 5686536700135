import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CrossIcon from '../../../assets/images/DedupeCross.png';
import DedupeTick from '../../../assets/images/DedupeTick.png';
import { connect } from 'react-redux';

class DedupeModal extends Component {
  render() {
    let { dedupeDetails } = this.props,
      { overAllDedupeStatus, dedupeResponseList } = dedupeDetails;

    const applicationColumns = [
      {
        Header: 'Parameters',
        accessor: 'parameter'
      },
      {
        Header: 'Value',
        accessor: 'value'
      },
      {
        Header: 'Status',
        accessor: 'dedupeStatus',
        Cell: original => {
          return original?.value ? (
            <img src={CrossIcon} alt='cross' className='icon' />
          ) : (
            <img src={DedupeTick} alt='tick' className='icon' />
          );
        }
      },
      {
        Header: 'User Id Matched',
        accessor: 'userList',
        Cell: original => {
          let { value } = original;
          return value?.length !== 0 ? (
            value?.map(matchedId => <div>{matchedId}</div>)
          ) : (
            <div>--</div>
          );
        }
      }
    ];

    return (
      <div className='dedupe-details-wrapper'>
        <h3 className='card_heading'> Dedupe Status</h3>
        <ReactTable
          data={dedupeResponseList}
          columns={applicationColumns}
          defaultPageSize={10}
          resizable={false}
          showPagination={false}
          minRows={6}
          sortable={false}
        />
        <div className='dedupe'>
          <b>Overal Status: </b>
          {overAllDedupeStatus ? (
            <span className=' dedupe_failed'>
              <img src={CrossIcon} alt='icon' className='icon' />
              Dedupe Failed
            </span>
          ) : (
            <span className=' dedupe_success'>
              <img src={DedupeTick} alt='icon' className='icon' />
              Dedupe Pass
            </span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ApplicationReducer }) => ({
  dedupeDetails: ApplicationReducer.dedupeDetails
});
export default connect(mapStateToProps)(DedupeModal);

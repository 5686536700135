//Environment Variable
const BASE_URL_PEMANT = process.env.REACT_APP_BASE_URL_PEMANT;
const BASE_URL_PEMANT_C1 = process.env.REACT_APP_BASE_URL_PEMANT_C1;

// User Application page APIS
const API_POST_LOANS = `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/allApplication`,
  API_GET_BUSINESS_DETAILS = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/business-details/${loanId}`,
  API_GET_ADDRESS = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/loan/fetch/address/${loanId}`,
  API_GET_PHOTOS = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/photo/fetch/photos/${loanId}`,
  API_GET_BANK_DETAILS = userId =>
    `${BASE_URL_PEMANT}/user-management/v1/bank/fetch/bank-details/${userId}`,
  API_GET_DEVICE_DETAILS = mobileNumber =>
    `${BASE_URL_PEMANT}/user-management/v1/user/fetch/${mobileNumber}`,
  API_GET_COMAPRE_DETAILS = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/compare/${loanId}`,
  API_GET_NOTES = (loanId, type) =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/notes/${loanId}/${type}`,
  API_POST_NOTES = `${BASE_URL_PEMANT}/loan-management/v1/dashboard/add/notes`,
  API_POST_CREATE_OFFER = `${BASE_URL_PEMANT}/user-management/v1/offer/create`,
  API_POST_REJECT_OFFER = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/reject/${loanId}`,
  API_GET_TRANSACTION_ANALYSIS = (
    loanId,
    mobileNumber
  ) => `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/transaction/${loanId}/${mobileNumber}
  `,
  API_POST_DEDUPE_STATUS = `${BASE_URL_PEMANT}/loan-management/v1/dashboard/get/dedupe/status`,
  API_POST_SMS_EXCEL = `${BASE_URL_PEMANT}/user-management/v1/user/excel-url`,
  //Dashboard apis
  API_GET_TRACKER = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/tracker/${loanId}`,
  API_POST_SEARCH_USER = `${BASE_URL_PEMANT}/loan-management/v1/dashboard/search/user`,
  // Login APIS
  API_POST_LOGIN = `${BASE_URL_PEMANT}/user-management/v1/user/login`,
  // Credit Behaviour  page APIS
  API_FETCH_ANALYSIS = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch/analysis/${loanId}`,
  API_GET_APPLICANT_SUMMARY = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/fetch-applicants-summary/${loanId}`,
  API_POST_UPDATE_BUREAU_TRADE_LINE = applicationNumber =>
    `/updateBureauTradeLine/${applicationNumber}`,
  // Offer page APIS
  API_GET_ACTIVE_LOAN = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/fetch/active/${loanId}`,
  API_GET_PROGRAMS = productName =>
    `${BASE_URL_PEMANT_C1}/product-management/v1/product/fetch/programs/${productName}`,
  API_POST_PRODUCT_BY_PROGRAM = `${BASE_URL_PEMANT_C1}/product-management/v1/product/fetch/product-by-program`,
  API_POST_OFFER_ACTIVE = `${BASE_URL_PEMANT}/user-management/v1/offer/activate`,
  API_POST_UPDATE_LIMIT_STATUS = `${BASE_URL_PEMANT_C1}/invoice-management/v1/limit/update/status`,
  API_GET_ALL_OFFERS = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/fetch/all/${loanId}`,
  API_GET_OFFER_HISTORY = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/fetch/history/${loanId}`,
  API_GET_OFFER_REGERATE_CHECK = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/regenerate-check/${loanId} `,
  API_GET_UNLOCK_OFFER = loanId =>
    `${BASE_URL_PEMANT}/user-management/v1/offer/unlock/${loanId}`,
  // Manage user APIS
  API_POST_CUSTOMER_AND_SUPPLIER_LIST = `${BASE_URL_PEMANT}/user-management/v1/onboarding/dashboard/contacts`,
  API_ADD_SUPPLIER = `${BASE_URL_PEMANT}/user-management/v1/onboarding/add/supplier`,
  API_POST_UPDATE_USER_STATUS = `${BASE_URL_PEMANT}/user-management/v1/onboarding/update/contact/status`,
  API_GET_FUNDING_SUMMARY = userId =>
    `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/fetch/funding-summary/${userId}`,
  API_GET_SUPPLIER_PAYMENT_DETAILS = supplierId =>
    `${BASE_URL_PEMANT}/user-management/v1/onboarding/supplier/payment-details/${supplierId}`,
  // Invoices APIS
  API_GET_VERIFIED_SUPPLIERS = userId =>
    `${BASE_URL_PEMANT}/user-management/v1/onboarding/fetch/verified-suppliers/${userId}`,
  API_POST_UPLOAD_INVOICE = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/upload/invoice`,
  API_POST_CONTACT = `${BASE_URL_PEMANT}/user-management/v1/onboarding/fetch/contact`,
  API_GET_LIMIT_SUMMARY = userId =>
    `${BASE_URL_PEMANT_C1}/invoice-management/v1/limit/summary/${userId}`,
  API_GET_INVOICE_SUMMARY = userId =>
    `${BASE_URL_PEMANT_C1}/invoice-management/v1/invoice/summary/${userId}`,
  API_POST_INVOICES = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/fetch/invoices`,
  API_POST_INVOICE_DETAILS = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/fetch/invoice-details`,
  // Payments APIS
  API_POST_ADD_PAYMENTS = `${BASE_URL_PEMANT_C1}/lms-service/v1/disbursal/add-payment`,
  API_POST_PAYMENT_HISTORY = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/fetch/payment-history`,
  API_POST_PAYMENTS_DETAILS = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/fetch/payment-details`,
  API_POST_FETCH_REPAYMENTS = `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/fetch/re-payments`,
  API_GET_REPAYMENT_DETAILS = paymentId =>
    `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/fetch/repayment/details/${paymentId}`,
  //SOA APIS
  API_GET_DASHBOARD_SOA = `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/getsoa`,
  API_GET_FETCH_CHARGES = `${BASE_URL_PEMANT_C1}/product-management/v1/master/fetch/charges`,
  API_POST_FETCH_CHARGE = `${BASE_URL_PEMANT_C1}/product-management/v1/master/fetch/charge`,
  API_POST_FETCH_GST_CHARGE = `${BASE_URL_PEMANT_C1}/product-management/v1/master/fetch/gst`,
  API_POST_DASHBOARD_CHARGE_POST = `${BASE_URL_PEMANT_C1}/invoice-management/v1/dashboard/post/charge`,
  API_POST_DASHBOARD_FETCH_CHARGES = `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/fetch/charges`,
  API_POST_CHARGE_WAIVE_OFF = `${BASE_URL_PEMANT_C1}/lms-service/v1/disbursal/charge-waiveOff`,
  API_GET_SOA_SUMMARY = userId =>
    `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/getsummary/${userId}`,
  API_GET_LIMIT_ACCOUNT_NUMBER = userId =>
    `${BASE_URL_PEMANT_C1}/invoice-management/v1/limit/fetch/limit-account-number/${userId}`,
  API_GET_SOA_EXCEL = `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/exportsoa/excel`,
  API_GET_EXPORT_SOA_EXCEL = `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/exportsoa/excel`,
  API_GET_SOA_INVOICE_DETAILS = invoiceId =>
    `${BASE_URL_PEMANT_C1}/lms-service/v1/dashboard/fetch/invoice-details/${invoiceId}`,
  //EOD APIS
  API_GET_EOD_SCHEDULE = `${BASE_URL_PEMANT_C1}/process-validator/v1/eod/schedule`,
  API_GET_EOD_HISTORY = `${BASE_URL_PEMANT_C1}/process-validator/v1/eod/history`,
  API_POST_EOD_START = `${BASE_URL_PEMANT_C1}/process-validator/v1/eod/start`,
  API_POST__SOD_START = `${BASE_URL_PEMANT_C1}/process-validator/v1/eod/sod`,
  //SERVICES APIS
  API_GET_ACTIVATE_LIMIT = loanId =>
    `${BASE_URL_PEMANT}/loan-management/v1/dashboard/activate-limit/${loanId}`,
  API_GET_GENERATE_MPIN = userId =>
    `${BASE_URL_PEMANT}/user-management/v1/mpin/generate/${userId}`;

export {
  API_POST_LOANS,
  API_GET_BUSINESS_DETAILS,
  API_GET_ADDRESS,
  API_GET_PHOTOS,
  API_GET_BANK_DETAILS,
  API_GET_DEVICE_DETAILS,
  API_GET_COMAPRE_DETAILS,
  API_GET_NOTES,
  API_POST_NOTES,
  API_POST_LOGIN,
  API_FETCH_ANALYSIS,
  API_GET_APPLICANT_SUMMARY,
  API_POST_UPDATE_BUREAU_TRADE_LINE,
  API_POST_CREATE_OFFER,
  API_POST_REJECT_OFFER,
  API_GET_ACTIVE_LOAN,
  API_GET_PROGRAMS,
  API_POST_PRODUCT_BY_PROGRAM,
  API_POST_OFFER_ACTIVE,
  API_POST_UPDATE_LIMIT_STATUS,
  API_ADD_SUPPLIER,
  API_GET_VERIFIED_SUPPLIERS,
  API_POST_CONTACT,
  API_POST_UPLOAD_INVOICE,
  API_POST_ADD_PAYMENTS,
  API_GET_DASHBOARD_SOA,
  API_GET_ALL_OFFERS,
  API_GET_OFFER_HISTORY,
  API_GET_FETCH_CHARGES,
  API_POST_FETCH_CHARGE,
  API_POST_FETCH_GST_CHARGE,
  API_POST_DASHBOARD_CHARGE_POST,
  API_POST_DASHBOARD_FETCH_CHARGES,
  API_POST_CUSTOMER_AND_SUPPLIER_LIST,
  API_POST_UPDATE_USER_STATUS,
  API_POST_CHARGE_WAIVE_OFF,
  API_GET_SOA_SUMMARY,
  API_GET_LIMIT_SUMMARY,
  API_GET_INVOICE_SUMMARY,
  API_POST_PAYMENT_HISTORY,
  API_POST_INVOICES,
  API_POST_INVOICE_DETAILS,
  API_POST_PAYMENTS_DETAILS,
  API_GET_TRANSACTION_ANALYSIS,
  API_GET_OFFER_REGERATE_CHECK,
  API_GET_UNLOCK_OFFER,
  API_GET_FUNDING_SUMMARY,
  API_GET_LIMIT_ACCOUNT_NUMBER,
  API_POST_FETCH_REPAYMENTS,
  API_GET_TRACKER,
  API_GET_REPAYMENT_DETAILS,
  API_POST_SEARCH_USER,
  API_GET_EOD_SCHEDULE,
  API_GET_EOD_HISTORY,
  API_POST_EOD_START,
  API_POST__SOD_START,
  API_GET_SOA_EXCEL,
  API_GET_EXPORT_SOA_EXCEL,
  API_POST_DEDUPE_STATUS,
  API_POST_SMS_EXCEL,
  API_GET_ACTIVATE_LIMIT,
  API_GET_GENERATE_MPIN,
  API_GET_SUPPLIER_PAYMENT_DETAILS,
  API_GET_SOA_INVOICE_DETAILS
};

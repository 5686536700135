import * as CreditBehaviourConstant from './creditBehaviour.actions.constant';
import { GET, POST } from '../../../../utils/webAPI.service';
import {
  API_FETCH_ANALYSIS,
  API_GET_APPLICANT_SUMMARY,
  API_GET_BUSINESS_DETAILS
} from '../../../../utils/APIUrls';

const getApplicantsSummary = loanId => dispatch => {
  return GET(API_GET_APPLICANT_SUMMARY(loanId))
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({
          type: CreditBehaviourConstant.GET_APPLICANTS_SUMMARY,
          data: data.data
        });
        return data;
      } else if (data.code === 401) {
        alert(data?.message);
      } else {
        throw data;
      }
    })
    .catch(err => {
      throw err;
    });
};

const fetchAnalysis = (loanId, data) => dispatch => {
  return POST(API_FETCH_ANALYSIS(loanId), data)
    .then(({ data }) => {
      if (data.code === 200) {
        dispatch({
          type: CreditBehaviourConstant.FETCH_ANALYSIS,
          data: data.data
        });
        dispatch({
          type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
          status: true
        });
      } else if (data.code === 401) {
        alert(data?.message);
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const getBusinessDetails = loanId => {
  return dispatch => {
    return GET(API_GET_BUSINESS_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: CreditBehaviourConstant.SET_BUSINESS_DETAILS,
              data: data.data
            });
          }
        } else if (data.code === 401) {
          alert(data?.message);
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};
const setInitialResponse = status => ({
  type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
  status
});

export {
  getApplicantsSummary,
  fetchAnalysis,
  setInitialResponse,
  getBusinessDetails
};

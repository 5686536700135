const GET_APPLICANTS_SUMMARY = Symbol('GetApplicantsSummary');
const FETCH_ANALYSIS = Symbol('FetchAnalysis');
const SET_INITIAL_RESPONSE = Symbol('SetInitialResponse');
const SET_BUSINESS_DETAILS = Symbol('SetBusinessDetails');

export {
  GET_APPLICANTS_SUMMARY,
  FETCH_ANALYSIS,
  SET_INITIAL_RESPONSE,
  SET_BUSINESS_DETAILS
};

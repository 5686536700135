import React, { Component } from 'react';
import { API_ADD_SUPPLIER } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAndSupplierList } from './manageUser.actions';

class AddSupplierModal extends Component {
  state = {
    activeFigure: 'bank_transfer',
    isButtonDisabled: false
  };

  addSupplierHandler = e => {
    e.preventDefault();
    this.setState({
      isButtonDisabled: true
    });
    let {
        mobileNumber,
        name,
        accountNumber,
        ifsc,
        upiId,
        activeFigure,
        confirmAccountNumber
      } = this.state,
      dataToSend;
    if (confirmAccountNumber !== accountNumber) {
      alert('Account number does not match, please re-enter');
      return 0;
    }
    if (activeFigure === 'bank_transfer') {
      dataToSend = {
        mobileNumber,
        name,
        paymentMethodType: activeFigure,
        userId: this.props.propsData.match.params.userId,
        paymentDetailsRequest: {
          bankDetails: {
            accountNumber,
            ifsc
          },
          upiDetails: {}
        }
      };
    } else {
      dataToSend = {
        mobileNumber,
        name,
        paymentMethodType: activeFigure,
        userId: this.props.propsData.match.params.userId,
        paymentDetailsRequest: {
          bankDetails: {},
          upiDetails: { upiId }
        }
      };
    }

    POST(API_ADD_SUPPLIER, dataToSend)
      .then(({ data }) => {
        this.setState({
          isButtonDisabled: false
        });

        if (data.code === 200) {
          alert('Supplier added  successfully');
          let { toggleModal, actions } = this.props;
          toggleModal(AddSupplierModal);
          actions.getCustomerAndSupplierList(
            this.props.propsData.match.params.userId,
            'supplier',
            1
          );
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    let { activeFigure } = this.state;

    return (
      <form onSubmit={e => this.addSupplierHandler(e)}>
        <div className="modal-wrapper">
          <h3 className="card_heading">Add new Supplier</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="input_heading">
                Supplier Name
                <input
                  placeholder=" Enter the  Supplier Name"
                  value={this.state.name}
                  required
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input_heading">
                Supplier Mobile Number
                <input
                  placeholder=" Enter the Supplier Mobile  Number"
                  value={this.state.mobileNumber}
                  required
                  maxLength="10"
                  type="tel"
                  onChange={e =>
                    this.setState({ mobileNumber: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="card_heading_sub">Party Payment Details</div>
          <div className="row toggle_button_box">
            <div className="col-md-3 side_title">Select Payment Mode:</div>

            <div
              onClick={() => {
                this.setState({ activeFigure: 'bank_transfer' });
              }}
              className={
                activeFigure === 'bank_transfer'
                  ? 'col-md-3 toggle_button_active'
                  : 'col-md-3 toggle_button'
              }
            >
              Bank Details
            </div>
            <div
              onClick={() => {
                this.setState({ activeFigure: 'upi' });
              }}
              className={
                activeFigure === 'upi'
                  ? 'col-md-3 toggle_button_active'
                  : 'col-md-3 toggle_button'
              }
            >
              UPI ID
            </div>
          </div>

          {activeFigure === 'bank_transfer' ? (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input_heading">
                    Bank account number
                    <input
                      placeholder=" Enter the Bank account number"
                      value={this.state.accountNumber}
                      required
                      onPaste={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onCut={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={e =>
                        this.setState({ accountNumber: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_heading">
                    Bank account number
                    <input
                      placeholder=" Re-enter the Bank account number"
                      value={this.state.confirmAccountNumber}
                      required
                      onPaste={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onCut={e => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={e =>
                        this.setState({ confirmAccountNumber: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input_heading">
                    IFSC code
                    <input
                      placeholder="Enter the IFSC code"
                      value={this.state.ifsc}
                      required
                      onChange={e => this.setState({ ifsc: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {activeFigure === 'upi' ? (
            <div className="row">
              <div className="col-md-6">
                <div className="input_heading">
                  UPI ID
                  <input
                    placeholder="Enter the UPI ID"
                    value={this.state.upiId}
                    required
                    onChange={e => this.setState({ upiId: e.target.value })}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="save_button">
            <button
              className="save_button  button-success"
              type="submit"
              disabled={this.state.isButtonDisabled}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCustomerAndSupplierList
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplierModal);

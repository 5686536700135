import * as manageUserConstants from './manageUser.actions.constants';

const initialState = {
  invoiceSummary: {},
  customerList: {},
  supplierList: {},
  fundingSummary: {}
};

const manageUserReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case manageUserConstants.SET_INVOICE_SUMMARY:
      return { ...state, invoiceSummary: data };
    case manageUserConstants.SET_CUSTOMER_LIST:
      return { ...state, customerList: data };
    case manageUserConstants.SET_SUPPLIER_LIST:
      return { ...state, supplierList: data };
    case manageUserConstants.SET_FUNDING_SUMMARY:
      return { ...state, fundingSummary: data };
    default:
      return state;
  }
};

export default manageUserReducer;

import React, { Component } from 'react';
import Logo from '../../assets/images/logo.png';
import User from '../../assets/images/human2.png';
import Bell from '../../assets/images/bell2.png';

export default class index extends Component {
  state = {
    logout: false
  };

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    let { history } = this.props;
    history.push(`/`);
  };

  render() {
    let { logout } = this.state;

    return (
      <div className="header-wrapper container m_header-auto">
        <div className="row header-wrapper_container mx-2">
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-2">
                <img src={Logo} alt="company logo" className="logo_image" />
              </div>
              <div className="col-md-10 text-right"> {this.props.children}</div>
            </div>
          </div>

          <div className="col-md-1 ">
            <img src={Bell} alt="bell logo" className="user_image mr-5" />
            <div className="logout">
              <img
                onClick={() => this.setState({ logout: !logout })}
                src={User}
                alt="user logo"
                className="user_image "
              />
              <div
                className={`logout-content ${
                  logout ? ' logout-show' : 'logout-hide'
                }`}
              >
                <div onClick={this.logout}>Logout</div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

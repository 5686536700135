import React from 'react';
import { flatMap, get } from 'lodash';
import Highcharts from 'highcharts';
import { rupeeCommaFormatter } from '../../../../utils/utility';
import Icon from 'react-icons-kit';
import { rupee } from 'react-icons-kit/fa/rupee';
require('highcharts/modules/heatmap')(Highcharts);

const generateHeatMap = (config, data) => {
  Highcharts.chart(
    {
      chart: {
        renderTo: document.getElementById(config.renderTo),
        type: 'heatmap',
        backgroundColor: 'transparent',
        height: 50 * (config.yCategories.length + 1)
      },
      title: false,
      credits: false,
      xAxis: {
        categories: config.xCategories,
        title: {
          useHTML: true,
          enabled: true,
          text: '<p >Months : Latest To Old&nbsp&nbsp<span >&xrarr;</span></p>'
        }
      },
      plotOptions: {
        heatmap: {
          borderColor: '#fff',
          dataLabels: {
            style: {
              textOutline: 'none'
            }
          },
          turboThreshold: 2000
        }
      },
      yAxis: {
        categories: data && data.map(el => `${el.type}-L${el.sno + 1}`),
        title: null,
        reversed: true
      },
      legend: false,

      tooltip: {
        formatter: function() {
          let index = this.y;
          let openingDate = get(data, `[${index}].openingDate`) || 'NA';
          let closingDate = get(data, `[${index}].closingDate`) || 'NA';
          let reportingDate = get(data, `[${index}].reportingDate`) || 'NA';
          let overdueAmount = get(data, `[${index}].overdueAmount`) || 'NA';
          let status = get(data, `[${index}].status`) || 'NA';
          return `<b>Opening Date</b>-${openingDate} <br/><b>Closing Date</b>-${closingDate}<br/><b>Reporting Date</b>-${reportingDate}<br/><b>Overdue Amount-${overdueAmount}</b><br/><b>Status</b>-${status} `;
        },
        shared: true
      },

      series: [
        {
          name: config.series.name,
          borderWidth: 1,
          data: config.series.data,
          dataLabels: {
            enabled: true,
            color: '#000000'
          }
        }
      ]
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text('No Data Available', 140, 120)
          .css({
            color: '#001061',
            fontSize: '16px'
          })
          .add();
      }
    }
  );
};

const applicantsColumns = [
  {
    Cell: ({ original }) => {
      let index = get(original, 'sno');
      return <span>{index + 1}</span>;
    },
    Header: 'Loan No.',
    accessor: 'sno',
    width: 40,
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          background: rowInfo.row.closingDate === 'NA' ? '#8bd591' : null
        }
      };
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 95,
    Cell: ({ original }) => {
      let { type } = original;
      return <span title={type}>{type}</span>;
    },
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          background: rowInfo.row.closingDate === 'NA' ? '#8bd591' : null
        }
      };
    }
  },
  {
    Header: 'Lender Name',
    accessor: 'reportingMemberShortName',
    width: 120,
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          background: rowInfo.row.closingDate === 'NA' ? '#8bd591' : null
        }
      };
    }
  },
  {
    Header: 'Loan Amount',
    accessor: 'loanAmount',
    width: 70,
    Cell: ({ original }) => {
      let { loanAmount } = original;
      return <span title={loanAmount}>{rupeeCommaFormatter(loanAmount)}</span>;
    }
  },
  {
    Header: 'Overdue Amount',
    accessor: 'overdueAmount',
    width: 70,
    Cell: ({ original }) => {
      let { overdueAmount } = original;
      return (
        <span title={overdueAmount}>{rupeeCommaFormatter(overdueAmount)}</span>
      );
    }
  },
  {
    Header: 'Current Balance',
    accessor: 'currentBalance',
    width: 70,
    Cell: ({ original }) => {
      let { currentBalance } = original;
      return (
        <span title={currentBalance}>
          {rupeeCommaFormatter(currentBalance)}
        </span>
      );
    }
  },
  {
    Header: 'EMI Amount',
    accessor: 'emiAmount',
    width: 70,
    Cell: ({ original }) => {
      let { emiAmount } = original;
      return <span title={emiAmount}>{rupeeCommaFormatter(emiAmount)}</span>;
    }
  },
  {
    Header: 'Opening Date',
    accessor: 'openingDate',
    width: 85,
    Cell: ({ original }) => {
      let { openingDate } = original;
      return <span title={openingDate}>{openingDate}</span>;
    }
  },
  {
    Header: 'Closing Date',
    accessor: 'closingDate',
    width: 85,
    Cell: ({ original }) => {
      let { closingDate } = original;
      return <span title={closingDate}>{closingDate}</span>;
    }
  },
  {
    Header: 'Reporting Date',
    accessor: 'reportingDate',
    width: 85,
    Cell: ({ original }) => {
      let { reportingDate } = original;
      return <span title={reportingDate}>{reportingDate}</span>;
    }
  },
  {
    Header: 'Ownership',
    accessor: 'ownershipType',
    width: 70,
    Cell: ({ original }) => {
      let { ownershipType } = original;
      return <span title={ownershipType}>{ownershipType}</span>;
    }
  },
  {
    Header: 'Delinquency Trend (Last 6 Months)',
    accessor: '',
    sortable: false,
    width: 225,
    Cell: ({ original }) => {
      let deliquencyTrend6Months =
          get(original, 'deliquencyTrend6Months') || [],
        { poorRepayment } = original;
      return (
        <ul className={'deliquency-trend'}>
          {deliquencyTrend6Months.map((item, index) => {
            return (
              <li
                style={{
                  backgroundColor: poorRepayment
                    ? '#ed5a5a'
                    : item === 'AAA' ? null : getColorCode(item)
                }}
                key={index}
              >
                {item === 'AAA' ? '' : item}
              </li>
            );
          })}
        </ul>
      );
    }
  }
];
const enquriesColumns = [
  {
    Header: 'Member Name',
    accessor: 'enquiringMemberShortName'
  },
  {
    Header: 'Date',
    accessor: 'dateOfEnquiryFields'
  },
  {
    Header: 'Purpose',
    accessor: 'enquiryPurpose'
  },
  {
    Header: 'Amount',
    accessor: 'enquiryAmount',
    Cell: props => {
      return (
        <>
          <Icon icon={rupee} size={14} />
          {rupeeCommaFormatter(props?.value)}
          /-
        </>
      );
    }
  }
];
const xCategories = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36
];
const DPD = ['SMA', 'SUB', 'DBT', 'LSS'];

const getYCategories = length => {
  let list = [];
  for (let i = 0; i < length; i++) {
    list.push(`Loan ${i + 1}`);
  }
  return list;
};

const getColorCode = val => {
  let hexCode;
  if (Number(val) >= 30 || DPD.includes(val)) {
    hexCode = '#ed5a5a';
  } else if (Number(val) < 30 && Number(val) > 0) {
    hexCode = '#f0ce70';
  } else if (val === 'XXX') {
    hexCode = '#e5e5e5';
  } else if (val === 'AAA') {
    hexCode = '#fff';
  } else if (Number(val) === 0 || val === 'STD') {
    hexCode = '#8bd591';
  }
  return hexCode;
};

const getHeatmapData = data => {
  let checkList1 = [];
  let checkList2 = [];
  data.forEach((itemA, indexA) => {
    let arr = itemA.delinquencyTrend36Months.map((itemB, indexB) => ({
      x: indexB,
      y: indexA,
      value: itemB === 'AAA' ? '   ' : itemB,
      name: itemB === 'AAA' ? '   ' : itemB,
      color: itemA.poorRepayment ? '#ed5a5a' : getColorCode(itemB)
    }));
    checkList1.push(arr);
  });
  for (let i = 0; i < checkList1.length; i++) {
    let status = false;
    let returnArr = [];
    for (let a = 0; a < checkList1[i].length; a++) {
      if (DPD.includes(checkList1[i][a].value)) {
        status = true;
        break;
      }
    }
    if (status) {
      returnArr = checkList1[i].map(item => ({
        ...item,
        color: '#ed5a5a'
      }));
    } else {
      returnArr = checkList1[i];
    }
    checkList2.push(returnArr);
  }
  return flatMap(checkList2);
};

export {
  generateHeatMap,
  applicantsColumns,
  enquriesColumns,
  xCategories,
  getColorCode,
  getYCategories,
  getHeatmapData
};

import React, { Component } from 'react';
import ReactTable from 'react-table';
import { API_POST_CHARGE_WAIVE_OFF } from '../../../utils/APIUrls';
import { POST } from '../../../utils/webAPI.service';
import { connect } from 'react-redux';
import {
  getSoa,
  getLimitAccountNumber,
  getSoaSummary
} from './interestAndCharges.actions';
import { bindActionCreators } from 'redux';

class WaiveOffModal extends Component {
  state = {
    isEditable: true,
    isButtonDisabled: false
  };

  componentDidMount = () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { getLimitAccountNumber } = actions;
    getLimitAccountNumber(userId);
  };

  submitHandler = () => {
    let { rowData, match } = this.props,
      { userId } = match.params,
      { comment, serviceRequestBy } = this.state,
      dataToSend = {
        transactionInfoId: rowData?.id,
        userId,
        comment,
        serviceRequestBy
      };
    this.setState({
      isButtonDisabled: true
    });
    POST(API_POST_CHARGE_WAIVE_OFF, dataToSend)
      .then(({ data }) => {
        this.setState({
          isButtonDisabled: false
        });
        if (data.code === 200) {
          let { toggleModal, match, actions } = this.props,
            { userId } = match.params;
          alert('Charge waived of successfully');
          actions.getSoa(userId);
          actions.getSoaSummary(userId);
          toggleModal('');
        } else if (data.code === 999 || data.code === 401) {
          alert(data.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  render() {
    let { rowData, toggleModal, limitAccountNumber } = this.props,
      { isEditable } = this.state;
    const outstandingInvoiceColumns = [
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate'
      },
      {
        Header: 'Transaction Date',
        accessor: 'transactionDate'
      },
      {
        Header: 'Transaction Description',
        accessor: 'transactionDescription'
      },
      {
        Header: 'Invoice No.',
        accessor: 'invoiceNumber'
      },
      {
        Header: 'Debit',
        accessor: 'debitAmount'
      }
    ];

    return (
      <div className="modal-wrapper">
        <h3 className="card_heading">Waiver</h3>
        <ReactTable
          columns={outstandingInvoiceColumns}
          data={[rowData]}
          defaultPageSize={1}
          showPagination={false}
          getTrProps={this.rowClicked}
        />
        <div className="row py-5 px-2">
          <div className="col-md-4">
            Service Request from
            <label className="radio_button-label">
              <input
                type="radio"
                className="radio_button"
                onChange={this.handleChange}
                value="user"
                name="serviceRequestBy"
              />User
            </label>
            <label className="radio_button-label">
              <input
                type="radio"
                className="radio_button"
                onChange={this.handleChange}
                value="ops_user"
                name="serviceRequestBy"
              />Operation Team
            </label>
          </div>
          <div className="col-md-8">
            Comment
            <div
              className="comments"
              contentEditable={isEditable}
              onInput={e =>
                this.setState({
                  comment: e.currentTarget.textContent
                })
              }
            />
          </div>
        </div>

        <div className="text-centre">
          You are about to Wave off ₹ {rowData?.debitAmount} for Loan account
          Number: {limitAccountNumber?.data || '-'}
        </div>
        <div className="save_button">
          <button
            className={'button-failure basic-button'}
            onClick={() => toggleModal('')}
          >
            Cancel
          </button>
          <button
            className={'button-success basic-button'}
            type="submit"
            onClick={this.submitHandler}
            disabled={this.state.isButtonDisabled}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ interestAndChargesReducer }) => ({
  limitAccountNumber: interestAndChargesReducer.limitAccountNumber
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getSoa, getLimitAccountNumber, getSoaSummary },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(WaiveOffModal);

import { combineReducers } from 'redux';
import { listData } from './listData.reducers';
import ApplicationReducer from '../views/LeadManagement/Application/application.reducers';
import offerReducer from '../views/LeadManagement/Offer/offer.reducers';
import invoicesReducer from '../views/LeadManagement/Invoices/invoices.reducers';
import { leadCreditBehaviour } from '../views/LeadManagement/Application/CreditBehaviour/creditBehaviour.reducers';
import interestAndChargesReducer from '../views/LeadManagement/InterestAndCharges/interestAndCharges.reducers';
import leadManagementNavsReducer from '../views/LeadManagement/LeadManagementNavs/LeadManagementNavs.reducers';
import manageUserReducer from '../views/LeadManagement/ManageUser/manageUser.reducers';
import paymentsReducer from '../views/LeadManagement/Payments/payments.reducers';
import eodManagementReducer from '../views/EodManagement/eodManagement.reducers';
const initialState = {
  isAuthenticated: false,
  isLogin: false,
  loaderModal: false
};
function data(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADER_MODAL_STATUS':
      return {
        ...state,
        loaderModal: action.val
      };

    default:
      return state;
  }
}

const reducers = combineReducers({
  data,
  listData,
  ApplicationReducer,
  offerReducer,
  invoicesReducer,
  leadCreditBehaviour,
  interestAndChargesReducer,
  leadManagementNavsReducer,
  manageUserReducer,
  paymentsReducer,
  eodManagementReducer
});

export default reducers;

import React, { Component } from 'react';
import ReactTable from 'react-table';
import { API_GET_SOA_INVOICE_DETAILS } from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class InvoiceInterestDetails extends Component {
  state = {
    invoiceInterestData: {}
  };
  componentDidMount() {
    const { rowData } = this.props,
      { invoiceNumber } = rowData;

    GET(API_GET_SOA_INVOICE_DETAILS(invoiceNumber))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({ invoiceInterestData: data?.data });
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  }
  render() {
    const InterestColumns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Principal Amount',
        accessor: 'principalAmount',
        Cell: props => {
          return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
        }
      },
      {
        Header: 'Interest Due',
        accessor: 'interestDue',
        Cell: props => {
          return <div>{`${props?.value} %` || '-'}</div>;
        }
      },

      {
        Header: 'Accrued Interest Amount',
        accessor: 'accruedInterestAmount',
        Cell: props => {
          return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
        }
      }
    ];

    const {
      accruedInterestDate,
      createdDate,
      extensionInterestRate,
      gracePeriod,
      monthlyInterestRate,
      paymentDueDate,
      paymentType,
      principalAmount,
      standardTenure,
      supplierName,
      totalAccuredInterestTillDate,
      transactionDate,
      interestPerDay
    } = this.state.invoiceInterestData;
    const { rowData } = this.props;
    return (
      <div className='modal-wrapper manage_user-wrapper'>
        <h3 className='card_heading'>Invoice No: {rowData?.invoiceNumber}</h3>
        <div className='row'>
          <div className='col-md-5 text-grey'>
            Supplier Name <div className='text-bold'>{supplierName || '-'}</div>
            Monthly Interest Rate{' '}
            <div className='text-bold'>{`${monthlyInterestRate} %` || '-'}</div>
            Standard tenure(in days){' '}
            <div className='text-bold'>{`${standardTenure} days` || '-'}</div>
            Transaction Date{' '}
            <div className='text-bold'>{transactionDate || '-'}</div>
            Accrued Interest Date{' '}
            <div className='text-bold'>{accruedInterestDate || '-'}</div>
            Extension Interest Rate{' '}
            <div className='text-bold'>
              {`${extensionInterestRate} %` || '-'}
            </div>
          </div>
          <div className='col-md-5 text-grey'>
            Payment Type <div className='text-bold'>{paymentType || '-'}</div>
            Principal Amount{' '}
            <div className='text-bold'>
              {rupeeDecimalFormatter(principalAmount, '₹') || '-'}
            </div>
            Created Date <div className='text-bold'>{createdDate || '-'}</div>
            Payment Due Date{' '}
            <div className='text-bold'>{paymentDueDate || '-'}</div>
            Grace Period{' '}
            <div className='text-bold'>{`${gracePeriod} days` || '-'}</div>
            Total Accured Interest Till Date{' '}
            <div className='text-bold'>
              {rupeeDecimalFormatter(totalAccuredInterestTillDate, '₹') || '-'}
            </div>
          </div>
        </div>

        <h3 className='card_heading mt-4'>Per Day Interest</h3>
        <ReactTable
          columns={InterestColumns}
          data={interestPerDay || []}
          defaultPageSize={200}
          showPagination={false}
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import DownArrow from '../../../assets/images/downArrow.png';
import { rupeeDecimalFormatter } from '../../../utils/utility';

export default class SettledInvoiceModal extends Component {
  render() {
    let { stateData } = this.props,
      { settledInvoiceDetails } = stateData,
      {
        invoiceStatus,
        daysOutstanding,
        repaymentMode,
        repaymentRefernce,
        principleAmount,
        interest,
        invoiceFileUrl
      } = settledInvoiceDetails;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>
          Settled Invoice Details
          <img src={DownArrow} alt='download' className='downArrow' />
        </h3>
        <div className='row'>
          <div className='col-md-3 text-grey'>
            Invoice Status
            <div className='text-bold'>{invoiceStatus || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Days Outstanding
            <div className='text-bold'>{daysOutstanding || '-'} Days</div>
          </div>
          <div className='col-md-3 text-grey'>
            Principal Amount
            <div className='text-bold'>
              {rupeeDecimalFormatter(principleAmount, '₹')}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 text-grey'>
            Repayment Mode
            <div className='text-bold'>{repaymentMode || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Repayment Reference
            <div className='text-bold'>{repaymentRefernce || '-'}</div>
          </div>
          <div className='col-md-3 text-grey'>
            Interest
            <div className='text-bold'>
              {rupeeDecimalFormatter(interest, '₹')}
            </div>
          </div>
        </div>

        <h3 className='card_heading'>
          Uploaded Invoice
          <a href={invoiceFileUrl} download>
            <img src={DownArrow} alt='download' className='downArrow' />
          </a>
        </h3>
        <img src={invoiceFileUrl} alt='image' className='invoice_img' />
      </div>
    );
  }
}

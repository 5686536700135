import React, { Component } from 'react';
import {
  API_GET_ACTIVATE_LIMIT,
  API_GET_GENERATE_MPIN,
  API_POST_UPDATE_LIMIT_STATUS
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';

export class LimitActivation extends Component {
  activateLimitHandler = () => {
    let { propsData } = this.props,
      { match, actions } = propsData;
    GET(API_GET_ACTIVATE_LIMIT(match.params.loanId))
      .then(({ data }) => {
        let { toggleModal } = this.props;
        if (data.code === 200) {
          alert('Limit Activated successfully');
          toggleModal('');
          actions.getLimitAccountNumber(match.params.userId);
          actions.getLimitSummary(match.params.userId);
        } else {
          alert(data?.message);
          toggleModal('');
        }
      })
      .catch(err => {
        throw err;
      });
  };
  render() {
    let { toggleModal } = this.props;

    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Alert</h3>

          <div className='paragraph'>
            Are you sure you want to activate the limit ?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => toggleModal('')}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={this.activateLimitHandler}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}

export class MpinGeneration extends Component {
  generateMpinHandler = () => {
    let { propsData } = this.props,
      { params } = propsData?.match;
    GET(API_GET_GENERATE_MPIN(params.userId))
      .then(({ data }) => {
        let { toggleModal } = this.props;
        if (data.code === 200) {
          alert('MPIN generated successfully');
          toggleModal('');
        } else {
          alert(data?.message);
          toggleModal('');
        }
      })
      .catch(err => {
        throw err;
      });
  };
  render() {
    let { toggleModal } = this.props;

    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Alert</h3>

          <div className='paragraph'>
            Are you sure you want to generate mpin ?
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => toggleModal('')}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={this.generateMpinHandler}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}

export class LimitStatus extends Component {
  statusChangeHandler = e => {
    let { stateData, propsData, toggleModal } = this.props,
      { activeLoans, match, actions } = propsData,
      { userId } = match.params,
      { offerId } = activeLoans,
      dataToSend = {
        limitStatus: stateData?.limitUpdatedStatus,
        offerId,
        userId
      };
    POST(API_POST_UPDATE_LIMIT_STATUS, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Limit Status updated successfully');
          toggleModal('');
        } else {
          alert(data?.message);
          toggleModal('');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    let { toggleModal, stateData } = this.props;

    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Alert</h3>

          <div className='paragraph'>
            {`Are you sure you want to update limit status to ${stateData.limitUpdatedStatus} ?`}
          </div>
          <div className='row buttons_wrapper'>
            <button
              className='col-md-2 button cancel_button mr-4'
              onClick={() => toggleModal('')}
            >
              Cancel
            </button>
            <button
              className='col-md-2 button add_button'
              onClick={this.statusChangeHandler}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}

import React from 'react';

export default function index({ heading, children, className }) {
  return (
    <div className={`card__wrapper ${className}`}>
      <div className='card-heading'>{heading}</div>
      <div className='card-body'>{children}</div>
    </div>
  );
}

import * as CreditBehaviourConstant from "./creditBehaviour.actions.constant";

const initialState = {
  initialResponse: false,
  applicantsSummary: [],
  analysisData: {},
  promoterData: {}
};

export const leadCreditBehaviour = (state = initialState, action) => {
  const { data, type, status } = action;
  switch (type) {
    case CreditBehaviourConstant.GET_APPLICANTS_SUMMARY:
      return { ...state, applicantsSummary: data };
    case CreditBehaviourConstant.FETCH_ANALYSIS:
      return { ...state, analysisData: data };
    case CreditBehaviourConstant.SET_INITIAL_RESPONSE:
      return { ...state, initialResponse: status };
    case CreditBehaviourConstant.SET_BUSINESS_DETAILS:
      return { ...state, promoterData: data };
    default:
      return state;
  }
};

import * as applicationConstants from './application.actions.constants';

const initialState = {
  promoterData: {},
  address: {},
  bankDetails: {},
  compareDetails: {},
  deviceDetails: {},
  comment: '',
  condition: '',
  photos: {},
  programs: [],
  transactionDetails: {},
  dedupeDetails: {}
};

const ApplicationReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case applicationConstants.SET_BUSINESS_DETAILS:
      return { ...state, promoterData: data };
    case applicationConstants.SET_ADDRESS_DETAILS:
      return { ...state, address: data };
    case applicationConstants.SET_BANK_DETAILS:
      return { ...state, bankDetails: data };
    case applicationConstants.SET_COMPARE_DETAILS:
      return { ...state, compareDetails: data };
    case applicationConstants.SET_DEVICE_DETAILS:
      return { ...state, deviceDetails: data };
    case applicationConstants.SET_COMMENT_NOTES:
      return { ...state, comment: data };
    case applicationConstants.SET_CONDITION_NOTES:
      return { ...state, condition: data };
    case applicationConstants.SET_PHOTOS_DETAILS:
      return { ...state, photos: data };
    case applicationConstants.SET_PROGRAMS:
      return { ...state, programs: data.responseList };
    case applicationConstants.SET_TRANSACTION_DETAILS:
      return { ...state, transactionDetails: data };
    case applicationConstants.SET_DEDUPE_DETAILS:
      return { ...state, dedupeDetails: data };
    default:
      return state;
  }
};

export default ApplicationReducer;



export function loaderModal(val) {
  return {
    type: "SET_LOADER_MODAL_STATUS",
    val
  };
}





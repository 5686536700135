import React, { Component } from 'react';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { Tabs, Tab, Navbar, Nav } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { rupeeCommaFormatter } from '../../../../utils/utility';
import { rupee } from 'react-icons-kit/fa/rupee';
import { ic_cloud_download } from 'react-icons-kit/md/ic_cloud_download';
import { checkSquare } from 'react-icons-kit/fa/checkSquare';
import {
  generateHeatMap,
  enquriesColumns,
  applicantsColumns,
  xCategories,
  getHeatmapData,
  getYCategories
} from './creditBehaviour.config';
import {
  fetchAnalysis,
  getApplicantsSummary,
  setInitialResponse,
  getBusinessDetails
} from './creditBehvaiour.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import Icon from 'react-icons-kit';
import { flag } from 'react-icons-kit/fa/flag';
import { Helmet } from 'react-helmet';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { POST } from '../../../../utils/webAPI.service';
import { API_POST_UPDATE_BUREAU_TRADE_LINE } from '../../../../utils/APIUrls';
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel';
import HeaderTop from '../../../../components/HeaderTop';

class LeadCreditBehaviour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantsData: [],
      filters: {
        businessLoan: false,
        closedLoan: false,
        liveLoan: false,
        loanWithDPD: false,
        nonFundedLoan: false,
        otherLoan: false,
        propertyLoan: false,
        vehicleLoan: false
      },
      activePAN: ''
    };
  }

  setFilters = name => {
    let { filters } = this.state;
    let prevValue = filters[name];
    filters[name] = !prevValue;
    this.setState({ filters }, () => this.setApplicantsData());
  };

  clearFilters = () => {
    let filters = {};
    for (let item in this.state.filters) {
      if (this.state.filters.hasOwnProperty(item)) {
        filters[item] = false;
      }
    }
    this.setState({ filters }, this.setApplicantsData);
  };

  setApplicantsData = async () => {
    const { filters, activePAN } = this.state,
      { actions, match } = this.props,
      loanId = match?.params?.loanId,
      { loaderModal, fetchAnalysis } = actions;
    let dataToSend = {
      data: {
        pan: activePAN,
        ...filters
      }
    };
    await fetchAnalysis(loanId, dataToSend);
    let { analysisData } = this.props;
    const data = get(analysisData, 'loanDetails') || [];
    this.setState({ applicantsData: data }, () => {
      this.createHeatMap(this.state.applicantsData, activePAN);
    });
  };

  toggleTab = async pan => {
    await this.setState({ activePAN: pan });

    const { filters, activePAN } = this.state,
      { actions, match } = this.props,
      loanId = match?.params?.loanId,
      { loaderModal, fetchAnalysis } = actions;
    Object.keys(filters).forEach(function(key) {
      filters[key] = false;
    });
    let dataToSend = {
      data: {
        pan: activePAN,
        ...filters
      }
    };
    await fetchAnalysis(loanId, dataToSend);
    let { analysisData } = this.props;
    const data = get(analysisData, 'loanDetails') || [];
    this.setState({ applicantsData: data }, () => {
      this.createHeatMap(this.state.applicantsData, activePAN);
    });
  };

  fetchDetails = async () => {
    const { actions, history, match } = this.props,
      loanId = match?.params?.loanId,
      { getApplicantsSummary, fetchAnalysis } = actions;

    if (loanId) {
      await getApplicantsSummary(loanId);

      const { applicantsSummary } = this.props;
      this.setState({ activePAN: applicantsSummary[0].pan });
      let dataToSend = {
        data: {
          pan: applicantsSummary[0].pan,
          ...this.state.filters
        }
      };

      await fetchAnalysis(loanId, dataToSend);
      const { analysisData } = this.props,
        data = get(analysisData, 'loanDetails') || [];
      document.getElementById(applicantsSummary[0].pan) &&
        this.createHeatMap(data, applicantsSummary[0].pan);
      this.setState({ applicantsData: data });
    }
  };

  createHeatMap = (data, renderTo) => {
    let deliquencyMonthsData = getHeatmapData(data);
    let yCategories = getYCategories(data.length);
    let config = {
      renderTo,
      xCategories,
      yCategories,
      series: {
        name: '',
        data: deliquencyMonthsData
      }
    };
    generateHeatMap(config, data);
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount = async () => {
    let { match, actions } = this.props,
      { params } = match,
      { getBusinessDetails } = actions;

    await getBusinessDetails(params.loanId);
    this.fetchDetails();
  };
  remarksHandler = e => {
    this.setState({ remark: e.target.value });
  };
  componentWillUnmount() {
    let { setInitialResponse } = this.props.actions;
    setInitialResponse(false);
  }
  editHandler = sno => {
    this.setState({ [sno]: !this.state[sno] });
  };
  handleTabSelect = key => {
    this.setState({ key });
  };
  lenderNameHandler = async data => {
    let { fetchAnalysis } = this.props.actions,
      { loanId } = this.props.match.params;
    this.setState({ [data.sno]: !this.state[data.sno] });
    var dataToSend = {
      data: {
        comment: this.state.remark,
        pan: data.pancard,
        seqNo: data.sno,
        lenderName: data.lender,
        isTradeLine: data.isTradeLine,
        isEmiObligated: data.isEmiObligated,
        obligatedEMI: 1000
      }
    };
    await POST(API_POST_UPDATE_BUREAU_TRADE_LINE(loanId), dataToSend)
      .then(({ data, status }) => {
        if (data.status === 'success') {
          let dataSend = {
            data: {
              pan: this.state.activePAN,
              ...this.state.filters
            }
          };
          setTimeout(async () => {
            await fetchAnalysis(loanId, dataSend);
            const { analysisData } = this.props,
              data = get(analysisData, 'loanDetails') || [];
            this.setState({ applicantsData: data });
          }, 500);
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row.closingDate === 'NA' ? '#00cdbe' : null
        }
      };
    }
    return {};
  };
  render() {
    const cibilDownload = original => {};
    const _applicantsColumns = [
      ...applicantsColumns.slice(0, 10),
      {
        Header: 'Comment',
        accessor: '',
        width: 60,
        Cell: ({ original }) => {
          let { comment } = original;
          return !this.state[original.sno] ? (
            <span title={comment}>
              {comment}
              <Icon
                icon={ic_mode_edit}
                size={16}
                onClick={() => this.editHandler(original.sno)}
              />{' '}
            </span>
          ) : (
            <span>
              <textarea
                name='Remarks'
                rows='2'
                className='text-input-field'
                onChange={this.remarksHandler}
                defaultValue={comment}
              />
              <br />
              <Icon
                icon={checkSquare}
                size={16}
                onClick={() => this.lenderNameHandler(original)}
              />{' '}
            </span>
          );
        }
      },
      ...applicantsColumns.slice(10)
    ];
    const cibilColumns = [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Case Role',
        accessor: 'caseRole',
        width: 125
      },
      {
        Header: 'Age',
        accessor: 'age',
        width: 75
      },
      {
        Header: 'Credit Score',
        accessor: 'cibilScore',
        width: 100,
        Cell: ({ original }) => {
          let { cibilScore, scoreComment } = original;
          let style = { fontFamily: 'Helvetica Bold' };
          let { match } = this.props;
          let custId = get(match, 'params.custId', '');
          return (
            <div>
              <span
                style={{
                  color: cibilScore > 600 ? '#8bd591' : '#ed5a5a',
                  ...style
                }}
              >
                {cibilScore}
                <a
                  download
                  // href={`/Backend/generateCibilPdf/${custId}/${original.pan}`}
                  style={{ marginLeft: '15px' }}
                >
                  <Icon
                    icon={ic_cloud_download}
                    size={20}
                    onClick={() => cibilDownload(original)}
                  />
                </a>
              </span>
              {cibilScore === 'NTC' && <div>{scoreComment}</div>}
            </div>
          );
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Total Loans</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'liveLoans',
        width: 100,
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 5) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[5].live}</span>
                <span>{original.loanCount[5].closed}</span>
              </div>
            );
          } else {
            return null;
          }
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Business Loans</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'unSecuredLoans',
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 0) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[0].live}</span>
                <span>{original.loanCount[0].closed}</span>
              </div>
            );
          }
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Property Loans</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'securedLoans',
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 1) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[1].live}</span>
                <span>{original.loanCount[1].closed}</span>
              </div>
            );
          }
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Vehicle Loans</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'individualLoans',
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 4) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[4].live}</span>
                <span>{original.loanCount[4].closed}</span>
              </div>
            );
          }
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Other</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'businessLoans',
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 2) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[2].live}</span>
                <span>{original.loanCount[2].closed}</span>
              </div>
            );
          }
        }
      },
      {
        Header: () => (
          <div>
            <div className='heading'>Non-funded Facilities</div>
            <div className='subheading'>
              <span>Live</span>
              <span>Closed</span>
            </div>
          </div>
        ),
        accessor: 'businessLoans',
        Cell: ({ original }) => {
          if (original.loanCount && original.loanCount.length > 3) {
            return (
              <div className='subheading'>
                <span className='liveLoans'>{original.loanCount[3].live}</span>
                <span>{original.loanCount[3].closed}</span>
              </div>
            );
          }
        }
      }
    ];
    const { applicantsData, filters } = this.state,
      {
        applicantsSummary,
        initialResponse,
        analysisData,
        promoterData
      } = this.props,
      {
        stressInLast3Months,
        stressInLast12Months,
        stressInPropertyLoan,
        stressInBusinessLoan
      } = analysisData,
      vehicleLoanHistory = get(analysisData, 'vehicleLoanHistory') || {},
      businessLoanHistory = get(analysisData, 'businessLoanHistory') || {},
      propertyLoanHistory = get(analysisData, 'propertyLoanHistory') || {},
      allEnquiries = get(analysisData, 'allEnquiries') || [],
      vehicleEnquiries = get(analysisData, 'vehicleEnquiries') || [],
      businessEnquiries = get(analysisData, 'businessEnquiries') || [],
      propertyEnquiries = get(analysisData, 'enquries') || [];
    const { match } = this.props;
    const custId = get(match, 'params.custId', '');

    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Credit Behaviour</title>
        </Helmet>
        <HeaderTop {...this.props} />
        <section className={'leadmanager-navs-wrapper '}>
          <Navbar>
            <Nav className='container'>
              <Nav.Link className='nav-top'>
                Active applications <span className='logo'>{'>'}</span>{' '}
                <span className='value'>{promoterData?.entityName}</span> (
                {match?.params?.userId})
              </Nav.Link>{' '}
            </Nav>
          </Navbar>
        </section>
        <div className={'credit-behaviour-wrapper container'}>
          <div className={'box-wrapper'}>
            <div className={'box-header'}>
              <h4>Credit Summary of Applicants</h4>
            </div>
            <ReactTable
              data={applicantsSummary}
              columns={cibilColumns}
              resizable={false}
              showPagination={false}
              className={
                !applicantsSummary.length
                  ? 'no-data no-pagination m-b-0'
                  : 'm-b-0'
              }
              minRows={0}
            />
          </div>
          {initialResponse ? (
            <div>
              <h4>Loan Repayment History</h4>

              <Tabs
                defaultActiveKey={get(applicantsSummary, `[${0}].pan`, '')}
                onSelect={this.toggleTab}
                id='applicants-tabs'
                variant='tabs'
              >
                {applicantsSummary.map((item, index) => {
                  return (
                    <Tab.Content
                      key={index}
                      eventKey={item.pan}
                      title={item.name}
                    >
                      <div className={'row filters-box'}>
                        <div className={'col-md-10'}>
                          <div className={'row'}>
                            <div className={'col-md-2'}>
                              <label>Select Filters</label>
                            </div>
                            <div className={'col-md-10'}>
                              <ul>
                                <li>
                                  <CheckBox
                                    labelColor={'#a4a1fb'}
                                    checkboxColor={'#a4a1fb'}
                                    htmlFor={'filter1'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Loans with Delinquency'}
                                    checked={filters.loanWithDPD}
                                    onChange={() =>
                                      this.setFilters('loanWithDPD')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#36ddd1'}
                                    checkboxColor={'#36ddd1'}
                                    htmlFor={'filter2'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Live Loans'}
                                    checked={filters.liveLoan}
                                    onChange={() => this.setFilters('liveLoan')}
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#67caf2'}
                                    checkboxColor={'#67caf2'}
                                    htmlFor={'filter3'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Business Loans'}
                                    checked={filters.businessLoan}
                                    onChange={() =>
                                      this.setFilters('businessLoan')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#3670cc'}
                                    checkboxColor={'#3670cc'}
                                    htmlFor={'filter4'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Property Loans'}
                                    checked={filters.propertyLoan}
                                    onChange={() =>
                                      this.setFilters('propertyLoan')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#a4a1fb'}
                                    checkboxColor={'#a4a1fb'}
                                    htmlFor={'filter5'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Vehicle Loans'}
                                    checked={filters.vehicleLoan}
                                    onChange={() =>
                                      this.setFilters('vehicleLoan')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#a4a1fb'}
                                    checkboxColor={'#a4a1fb'}
                                    htmlFor={'filter6'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Non Funded Loans'}
                                    checked={filters.nonFundedLoan}
                                    onChange={() =>
                                      this.setFilters('nonFundedLoan')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#a4a1fb'}
                                    checkboxColor={'#a4a1fb'}
                                    htmlFor={'filter7'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Other Loans'}
                                    checked={filters.otherLoan}
                                    onChange={() =>
                                      this.setFilters('otherLoan')
                                    }
                                  />
                                </li>
                                <li>
                                  <CheckBox
                                    labelColor={'#a4a1fb'}
                                    checkboxColor={'#a4a1fb'}
                                    htmlFor={'filter8'}
                                    inputClass={''}
                                    labelClass={''}
                                    label={'Closed Loans'}
                                    checked={filters.closedLoan}
                                    onChange={() =>
                                      this.setFilters('closedLoan')
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className={'col-md-2 text-center'}>
                          <button onClick={this.clearFilters} type={'button'}>
                            Clear All Filers
                          </button>
                          <span
                            className='icon-download'
                            title='Excel Download'
                          >
                            <a href='#'>
                              <Icon icon={fileExcel} size={22} />
                            </a>
                          </span>
                        </div>
                      </div>
                      <ReactTable
                        className={
                          !applicantsData.length
                            ? 'no-data applicants-table'
                            : 'applicants-table'
                        }
                        data={applicantsData}
                        columns={_applicantsColumns}
                        resizable={false}
                        minRows={0}
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        defaultPageSize={10}
                      />
                      <div className={'box-wrapper'}>
                        <div className={'box-header'}>
                          <h4>Delinquency History of {item.name}</h4>
                        </div>
                        <div className={'chart-box'} id={item.pan} />
                        <div className={'box-footer'}>
                          <ul className={'list-unstyled'}>
                            <li>
                              <label>Stress Observed in Last 3 Months</label>
                              {stressInLast3Months ? (
                                <span style={{ color: '#ed5a5a' }}>
                                  YES <Icon icon={flag} size={22} />
                                </span>
                              ) : (
                                <span style={{ color: '#8bd591' }}>
                                  NO <Icon icon={flag} size={22} />
                                </span>
                              )}
                            </li>
                            <li>
                              <label>Stress Observed in Last 12 Months</label>
                              {stressInLast12Months ? (
                                <span style={{ color: '#ed5a5a' }}>
                                  YES <Icon icon={flag} size={22} />
                                </span>
                              ) : (
                                <span style={{ color: '#8bd591' }}>
                                  NO <Icon icon={flag} size={22} />
                                </span>
                              )}
                            </li>
                            <li>
                              <label>Stress Observed in Business Loans</label>
                              {stressInBusinessLoan ? (
                                <span style={{ color: '#ed5a5a' }}>
                                  YES <Icon icon={flag} size={22} />
                                </span>
                              ) : (
                                <span style={{ color: '#8bd591' }}>
                                  NO <Icon icon={flag} size={22} />
                                </span>
                              )}
                            </li>
                            <li>
                              <label>Stress Observed in Property Loans</label>
                              {stressInPropertyLoan ? (
                                <span style={{ color: '#ed5a5a' }}>
                                  YES <Icon icon={flag} size={22} />
                                </span>
                              ) : (
                                <span style={{ color: '#8bd591' }}>
                                  NO <Icon icon={flag} size={22} />
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={'box-wrapper secondary'}>
                        <div className={'box-header'}>
                          <h4>Loan Repayment Summary - Business Loans</h4>
                        </div>
                        <div className={'box-content'}>
                          <ul className={'list-unstyled'}>
                            <li>
                              <label className='boldText'>Business Loan</label>
                              <span>
                                {get(businessLoanHistory, 'totalLoans', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                &gt; 30 DPD Observed
                              </label>
                              <span>
                                {get(businessLoanHistory, 'dpdObserved', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>Highest Loan</label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(businessLoanHistory, 'highestLoan', '')
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Total Current Outstanding
                              </label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(
                                    businessLoanHistory,
                                    'currentOustanding',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li style={{ minWidth: '180px' }}>
                              <label className='boldText'>
                                Total Loan Taken in last 6 months
                              </label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(
                                    businessLoanHistory,
                                    'loanAmountInLast6Months',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                First Loan Taken On
                              </label>
                              <span>
                                {get(businessLoanHistory, 'firstLoanDate', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Most recent Loan Taken On
                              </label>
                              <span>
                                {get(businessLoanHistory, 'lastLoanDate', '')}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={'box-wrapper secondary'}>
                        <div className={'box-header'}>
                          <h4>Loan Repayment Summary - Property Loans</h4>
                        </div>
                        <div className={'box-content'}>
                          <ul className={'list-unstyled'}>
                            <li>
                              <label className='boldText'>Property Loan</label>
                              <span>
                                {get(propertyLoanHistory, 'totalLoans', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                &gt; 30 DPD Observed
                              </label>
                              <span>
                                {get(propertyLoanHistory, 'dpdObserved', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>Highest Loan</label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(propertyLoanHistory, 'highestLoan', '')
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Total Current Outstanding
                              </label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(
                                    propertyLoanHistory,
                                    'currentOustanding',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li style={{ minWidth: '180px' }}>
                              <label className='boldText'>
                                Total Loan Taken in last 6 months
                              </label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(
                                    propertyLoanHistory,
                                    'loanAmountInLast6Months',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                First Loan Taken On
                              </label>
                              <span>
                                {get(propertyLoanHistory, 'firstLoanDate', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Most recent Loan Taken On
                              </label>
                              <span>
                                {get(propertyLoanHistory, 'lastLoanDate', '')}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={'box-wrapper secondary'}>
                        <div className={'box-header'}>
                          <h4>Loan Repayment Summary - Vehicle Loans</h4>
                        </div>
                        <div className={'box-content'}>
                          <ul className={'list-unstyled'}>
                            <li>
                              <label className='boldText'>Vehicle Loan</label>
                              <span>
                                {get(vehicleLoanHistory, 'totalLoans', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                &gt; 30 DPD Observed
                              </label>
                              <span>
                                {get(vehicleLoanHistory, 'dpdObserved', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>Highest Loan</label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(vehicleLoanHistory, 'highestLoan', '')
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Total Current Outstanding
                              </label>
                              <span>
                                {rupeeCommaFormatter(
                                  get(
                                    vehicleLoanHistory,
                                    'currentOustanding',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li style={{ minWidth: '180px' }}>
                              <label className='boldText p-0'>
                                Total Loan Taken in last 6 months
                              </label>
                              <span>
                                <Icon icon={rupee} size={12} />

                                {rupeeCommaFormatter(
                                  get(
                                    vehicleLoanHistory,
                                    'loanAmountInLast6Months',
                                    ''
                                  )
                                )}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                First Loan Taken On
                              </label>
                              <span>
                                {get(vehicleLoanHistory, 'firstLoanDate', '')}
                              </span>
                            </li>
                            <li>
                              <label className='boldText'>
                                Most recent Loan Taken On
                              </label>
                              <span>
                                {get(vehicleLoanHistory, 'lastLoanDate', '')}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={'box-wrapper secondary'}>
                        <div className={'box-header'}>
                          <h4>Enquiries in Last 3 Months</h4>
                        </div>
                        <div className={'box-content'}>
                          <ul className={'list-unstyled'}>
                            <li>
                              <label className='boldText'>All Loans</label>
                              <span>{allEnquiries.length}</span>
                            </li>
                            <li>
                              <label className='boldText'>Business Loans</label>
                              <span>{businessEnquiries.length}</span>
                            </li>
                            <li>
                              <label className='boldText'>Property Loan</label>
                              <span>{propertyEnquiries.length}</span>
                            </li>
                            <li>
                              <label className='boldText'>Vehicle Loans</label>
                              <span>{vehicleEnquiries.length}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={'row'}>
                        <div className={'col-md-12'}>
                          <div className={'box-wrapper m-0'}>
                            <div className={'box-header'}>
                              <h4>Credit Inquiries</h4>
                              <Tabs
                                defaultActiveKey='all'
                                id='all-tabs'
                                activeKey={this.state.key}
                                onSelect={this.handleTabSelect}
                              >
                                <Tab.Content eventKey='all' title={'All'}>
                                  <ReactTable
                                    className={
                                      !allEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={allEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  title={'Business Loans'}
                                  eventKey='businessLoan'
                                >
                                  <ReactTable
                                    className={
                                      !businessEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={businessEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  eventKey='propertyLoan'
                                  title={'Property Loans'}
                                >
                                  <ReactTable
                                    className={
                                      !propertyEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={propertyEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                                <Tab.Content
                                  eventKey='vehicleLoan'
                                  title={'Vehicle Loans'}
                                >
                                  <ReactTable
                                    className={
                                      !vehicleEnquiries.length
                                        ? 'no-data m-b-0'
                                        : 'm-b-0'
                                    }
                                    data={vehicleEnquiries}
                                    columns={enquriesColumns}
                                    resizable={false}
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    defaultPageSize={10}
                                    minRows={0}
                                  />
                                </Tab.Content>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Content>
                  );
                })}
              </Tabs>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ leadCreditBehaviour }) => ({
  initialResponse: leadCreditBehaviour.initialResponse,
  applicantsSummary: leadCreditBehaviour.applicantsSummary,
  analysisData: leadCreditBehaviour.analysisData,
  promoterData: leadCreditBehaviour.promoterData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchAnalysis,
      getApplicantsSummary,
      setInitialResponse,
      getBusinessDetails
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadCreditBehaviour);

import React, { Component, Fragment } from 'react';
import Application from './Application/Application';
import Offer from './Offer/Offer';
import Payments from './Payments/Payments';
import Invoices from './Invoices/Invoices';
import InterestAndCharges from './InterestAndCharges/InterestAndCharges';
import ManagerUser from './ManageUser/ManagerUser';
import { Route, Switch } from 'react-router-dom';
import LeadManagementNavs from './LeadManagementNavs/LeadManagementNavs';
import LoaderModal from '../../components/LoaderModal';
import { get } from 'lodash';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../store/actions';
import Services from './Services/Services';

export const hist = createBrowserHistory();

class LeadManagementRoutes extends Component {
  render() {
    const arrPath = get(this, 'props.location.pathname', '').split('/');
    const activeTab = arrPath.length
      ? arrPath[arrPath.length - 1]
      : 'application';
    let { loaderModalStatus } = this.props;
    return (
      <>
        <LoaderModal status={loaderModalStatus} />
        <Fragment>
          <LeadManagementNavs active={activeTab} {...this.props} />
          <Switch>
            <Route
              path='/pay-app/:loanId/:userId/application'
              exact
              component={Application}
            />
            <Route
              path='/pay-app/:loanId/:userId/offer'
              exact
              component={Offer}
            />
            <Route
              path='/pay-app/:loanId/:userId/invoices'
              exact
              component={Invoices}
            />
            <Route
              path='/pay-app/:loanId/:userId/interestAndCharges'
              exact
              component={InterestAndCharges}
            />
            <Route
              path='/pay-app/:loanId/:userId/payments'
              exact
              component={Payments}
            />
            <Route
              path='/pay-app/:loanId/:userId/manageUser'
              exact
              component={ManagerUser}
            />
            <Route
              path='/pay-app/:loanId/:userId/services'
              exact
              component={Services}
            />
          </Switch>
        </Fragment>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadManagementRoutes);

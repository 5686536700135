import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { POST } from '../../../utils/webAPI.service';
import { API_POST_LOANS, API_POST_SEARCH_USER } from '../../../utils/APIUrls';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import CredoModal from '../../../components/CredoModal';
import TrackerModal from './trackerModal';
import { ProgressBar } from 'react-bootstrap';
import HeaderTop from '../../../components/HeaderTop';
import SearchIcon from '../../../assets/images/SearchIcon.svg';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import SettingLogo from '../../../assets/images/SettingIcon.svg';
class Dashboard extends Component {
  state = {
    applicationsData: [],
    activeFilter: 'ALL',
    currentPage: 1,
    totalPages: 1,
    modalOpen: false,
    searchFlag: false,
    sortUserId: -1,
    sortDateOfJoining: -1,
    sortingOn: ''
  };

  componentDidMount = async () => {
    let data = {
        loanStatus: 'ALL',
        pageNumber: 1,
        sortingRequest: {
          sortParameter: 0,
          sortingOn: ''
        }
      },
      token = localStorage.getItem('token'),
      { actions } = this.props;
    actions.loaderModal(true);

    if (token) {
      await POST(API_POST_LOANS, data)
        .then(({ data }) => {
          if (data.code === 200) {
            if (data?.data && data.data?.loanList) {
              this.setState({
                applicationsData: data.data.loanList,
                totalPages: data.data.totalPage
              });
            }
          } else {
            alert(data.message);
          }
          actions.loaderModal(false);
        })
        .catch(err => {
          throw err;
        });
    } else {
      let { history } = this.props;
      alert('Unauthorized access ');
      actions.loaderModal(false);
      history.push('/');
    }
  };

  rowClicked = (state, rowInfo, column) => {
    if (
      rowInfo &&
      rowInfo?.original &&
      rowInfo?.original?.loanId &&
      column?.Header !== 'Status'
    ) {
      return {
        onClick: () => {
          var win = window.open(
            `/pay-app/${rowInfo.original.loanId}/${rowInfo.original?.userId}/application`
          );
          win?.focus();
        }
      };
    }
    return false;
  };

  toggleModal = (loanId, userId) => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
      loanId,
      userId
    }));
  };

  getPageData = type => {
    let { currentPage, totalPages } = this.state;
    if (type === 'next' && currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 }, () => {
        this.fetchDetails();
      });
    } else if (type === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 }, () => {
        this.fetchDetails();
      });
    }
  };

  applicantTabToggle = item => {
    let { activeFilter } = this.state;
    this.setState(
      { currentPage: 1, activeFilter: item, searchFlag: false },
      () => {
        if (activeFilter !== item) {
          this.fetchDetails();
        }
      }
    );
  };

  fetchDetails = sortValue => {
    let {
        activeFilter,
        currentPage,
        sortUserId,
        sortDateOfJoining,
        sortingOn
      } = this.state,
      dataToSend = {
        loanStatus: activeFilter,
        pageNumber: currentPage,
        sortingRequest: {
          sortParameter:
            sortValue === 'userId' ? sortUserId : sortDateOfJoining,
          sortingOn
        }
      },
      { actions } = this.props;
    actions.loaderModal(true);

    POST(API_POST_LOANS, dataToSend)
      .then(({ data }) => {
        if (data?.data) {
          this.setState({
            applicationsData: data.data?.loanList,
            totalPages: data.data?.totalPage
          });
        } else {
          this.setState({
            applicationsData: []
          });
        }
        actions.loaderModal(false);
      })
      .catch(err => {
        throw err;
      });
  };

  onSearchHandler = e => {
    e.preventDefault();
    let { activeFilter, searchBy, searchValue } = this.state,
      { actions } = this.props,
      data = {
        loanStatus: activeFilter,
        searchType: searchBy,
        searchValue: searchValue
      };
    actions.loaderModal(true);
    POST(API_POST_SEARCH_USER, data)
      .then(({ data }) => {
        actions.loaderModal(false);
        if (data.code === 200) {
          this.setState({ applicationsData: [data?.data], searchFlag: true });
        } else {
          alert(data?.message);
        }
      })
      .catch(err => {
        throw err;
      });
  };

  searchSelectHandler = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getTheadThProps = (state, rowInfo, column, instance) => ({
    onClick: () => {
      let { sortUserId, sortDateOfJoining } = this.state;
      if (column?.id === 'userId') {
        this.setState(
          {
            sortUserId: sortUserId === -1 ? 1 : -1,
            sortDateOfJoining: -1,
            sortingOn: 'userId'
          },
          () => {
            this.fetchDetails('userId');
          }
        );
      } else if (column?.id === 'dateOfApplication') {
        this.setState(
          {
            sortDateOfJoining: sortDateOfJoining === -1 ? 1 : -1,
            sortUserId: -1,
            sortingOn: 'createdDate'
          },
          () => {
            this.fetchDetails('createdDate');
          }
        );
      }
    }
  });

  render() {
    let {
      currentPage,
      totalPages,
      activeFilter,
      modalOpen,
      searchFlag,
      searchBy,
      searchValue,
      sortDateOfJoining,
      sortUserId
    } = this.state;
    const applicationColumns = [
      {
        Header: () => (
          <span>
            User ID
            <img
              src={sortUserId === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'userId'
      },
      {
        Header: () => (
          <span>
            Date of Joining
            <img
              src={sortDateOfJoining === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'dateOfApplication',
        Cell: ({ original }) => {
          let { dateOfApplication } = original;
          return <div>{dateOfApplication}</div>;
        }
      },
      {
        Header: 'Business Name',
        accessor: 'businessName'
      },
      {
        Header: 'Promoter',
        accessor: 'promoter'
      },
      {
        Header: 'Mobile',
        accessor: 'mobile'
      },
      {
        Header: 'Limit Amount',
        accessor: 'limtAmount',
        headerStyle: { textAlign: 'right' },
        Cell: props => {
          return (
            <div className='right-aligned'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        }
      },
      {
        Header: 'Balance Limit',
        accessor: 'balanceLimit',
        headerStyle: { textAlign: 'right' },
        Cell: props => {
          return (
            <div className='right-aligned'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        }
      },
      {
        Header: 'Last Transaction',
        accessor: 'lastTransaction ',
        Cell: ({ original }) => {
          let { lastTransaction } = original;
          return <div>{lastTransaction}</div>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 80,
        Cell: ({ original }) => {
          let { status, stageCompletion, loanId, userId } = original,
            colorStyles;
          const obj = {
            Active: [' #39B73F', '#F4FBF4'],
            Blocked: [' #474747', ' #F5F5F5', '#474747'],
            Rejected: ['  #CA4949', ' #FAEFEF'],
            'On Hold': ['#FF7D1A', ' #FFF7EB'],
            Approved: ['#FFBF00', '#F4FBF4'],
            Pending: ['#4687f4', 'transparent']
          };
          for (const property in obj) {
            if (status === property) {
              colorStyles = {
                color: obj[property][0],
                backgroundColor: obj[property][1],
                borderLeftColor: obj[property][0]
              };
            }
          }

          return (
            <>
              {status === 'Pending' ||
              status === 'Approved' ||
              status === 'Auto Approved' ? (
                <>
                  <button
                    className='button_common'
                    onClick={() => this.toggleModal(loanId, userId)}
                    style={colorStyles}
                  >
                    {status}
                    {status === 'Pending' ? (
                      <ProgressBar now={stageCompletion} />
                    ) : (
                      <ProgressBar now={stageCompletion} variant='warning' />
                    )}
                  </button>
                </>
              ) : (
                <button className='button_common' style={colorStyles}>
                  {status}
                </button>
              )}
            </>
          );
        }
      }
    ];

    return (
      <div className='dashboard-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Dashboard</title>
        </Helmet>
        <HeaderTop {...this.props}>
          <form onSubmit={e => this.onSearchHandler(e)} className='search-form'>
            <select
              onChange={this.searchSelectHandler}
              name='searchBy'
              required
              value={searchBy}
            >
              <option disabled selected>
                Search By
              </option>
              <option>Mobile Number</option>
              <option>User Id</option>
            </select>

            <input
              required={true}
              type='number'
              placeholder='Search'
              inputValue={searchValue}
              name='searchValue'
              onChange={this.searchSelectHandler}
            />
            <button type='submit' className='search-form_submit'>
              <img src={SearchIcon} alt='icon' />
            </button>
            <img
              src={SettingLogo}
              alt=' logo'
              className='logo_image ml-5'
              onClick={() => {
                let { history } = this.props;
                history.push(`/pay-app/eod`);
              }}
            />
          </form>
        </HeaderTop>
        <div className='filter '>
          <div className='filter_container container'>
            <div
              onClick={() => this.applicantTabToggle('ALL')}
              className={
                this.state.activeFilter === 'ALL'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              ALL
            </div>
            <div
              onClick={() => this.applicantTabToggle('Active')}
              className={
                this.state.activeFilter === 'Active'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              ACTIVE
            </div>
            <div
              onClick={() => this.applicantTabToggle('Approved')}
              className={
                this.state.activeFilter === 'Approved'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              APPROVED
            </div>
            <div
              onClick={() => this.applicantTabToggle('Rejected')}
              className={
                this.state.activeFilter === 'Rejected'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              REJECTED
            </div>
            <div
              onClick={() => this.applicantTabToggle('On Hold')}
              className={
                this.state.activeFilter === 'On Hold'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              ON HOLD
            </div>
            <div
              onClick={() => this.applicantTabToggle('Blocked')}
              className={
                this.state.activeFilter === 'Blocked'
                  ? 'button filter_container_active-button'
                  : 'button filter_container_normal-button'
              }
            >
              BLOCKED
            </div>
            <div
              onClick={() => this.applicantTabToggle('Pending')}
              className={
                this.state.activeFilter === 'Pending'
                  ? 'button w-large filter_container_active-button'
                  : 'button w-large filter_container_normal-button'
              }
            >
              NO ACTION TAKEN
            </div>
          </div>
        </div>
        <div className={'datatable-wrapper container  mt-5'}>
          {!searchFlag ? (
            <>
              <ReactTable
                data={this.state.applicationsData}
                columns={applicationColumns}
                defaultPageSize={10}
                resizable={false}
                showPagination={false}
                minRows={10}
                getTdProps={this.rowClicked}
                getTheadThProps={this.getTheadThProps}
                sortable={false}
              />
              <ul className={'datatable-pagination'}>
                <li>
                  <button
                    disabled={!(currentPage > 1)}
                    className={'prev-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('prev', activeFilter)}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {currentPage} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > currentPage)}
                    className={'next-btn'}
                    type={'button'}
                    onClick={() => this.getPageData('next', activeFilter)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </>
          ) : (
            <ReactTable
              data={this.state.applicationsData}
              columns={applicationColumns}
              defaultPageSize={10}
              resizable={false}
              showPagination={false}
              minRows={1}
              sortable={false}
              getTdProps={this.rowClicked}
            />
          )}
        </div>
        <CredoModal
          isOpen={modalOpen}
          styles={{
            content: {
              minWidth: '90vw',
              height: '87vh'
            }
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={TrackerModal}
          stateData={this.state}
          propsData={this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

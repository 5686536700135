import React, { Component } from 'react';
import Tick from '../../../assets/images/tick.svg';
import AdhaarFront from '../../../assets/images/adhaarfront.svg';
import AdhaarBack from '../../../assets/images/adhaarBack.svg';
import PanImage from '../../../assets/images/panImage.svg';
import { GET } from '../../../utils/webAPI.service';
import { API_GET_TRACKER } from '../../../utils/APIUrls';

export default class TrackerModal extends Component {
  state = {
    tracker: []
  };
  componentDidMount = () => {
    let { stateData } = this.props,
      { loanId } = stateData;
    GET(API_GET_TRACKER(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          let { tracker = [], isPanManual, isAadharManual } = data?.data;
          this.setState({ tracker, isPanManual, isAadharManual });
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };
  render() {
    let { tracker, isAadharManual, isPanManual } = this.state,
      { stateData } = this.props,
      { userId } = stateData;
    return (
      <div className="modal-wrapper  tracker_wrapper">
        <h3 className="card_heading">Status - User ID {userId}</h3>
        <div className="main_tracker">
          <div className="main_tracker_box">
            {tracker?.map(item => {
              let { status } = item;
              let stageStatus = status === 'On Going' ? 'on_going' : status;
              return (
                <img
                  src={Tick}
                  alt="icon"
                  className={`icon_image ${stageStatus}`}
                />
              );
            })}
          </div>
        </div>

        <div className="sub_tracker">
          {tracker?.map((e, index) => {
            let { parentStageName, status, completedAt, childStages } = e;
            let completedAtDuration = completedAt?.split(/(\s+)/);
            return (
              <div className="sub_tracker_section">
                <div className="sub_tracker_section-top">
                  <div className="heading">{parentStageName}</div>
                  <div className="time">
                    <div>{completedAtDuration?.[0] || ''}</div>
                    <div>{completedAtDuration?.[2] || ''}</div>
                  </div>
                </div>
                <div className="sub_tracker_section-bottom">
                  <div className="sub_tracker_section-bottom-inner">
                    {childStages?.map(item => {
                      let { status, name, completedAt } = item;
                      let stageStatus =
                        status === 'On Going' ? 'on_going' : status;
                      let completedAtTime = completedAt?.split(/(\s+)/);
                      return (
                        <>
                          <div className="box">
                            <div className="box_circles">
                              <div className="box_circles_figure">
                                <img
                                  src={Tick}
                                  alt="icon"
                                  className={`icon_image ${stageStatus}`}
                                />
                              </div>
                              <div
                                className={`box_circles_content ${stageStatus} `}
                              >
                                {name}
                              </div>
                            </div>
                            <div className="box_time">
                              <div>{completedAtTime?.[0] || ''}</div>
                              <div>{completedAtTime?.[2] || ''}</div>
                            </div>
                          </div>
                          {isPanManual === false && name === 'PAN Upload' ? (
                            <img
                              src={PanImage}
                              alt="icon"
                              className="pl-5 ml-5"
                            />
                          ) : null}
                          {isAadharManual === false &&
                          name === 'Aadhar Upload' ? (
                            <>
                              <img
                                src={AdhaarBack}
                                alt="icon"
                                className="pl-5 ml-5"
                              />
                              <img
                                src={AdhaarFront}
                                alt="icon"
                                className="pl-3"
                              />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

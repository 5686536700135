import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  API_POST_OFFER_ACTIVE,
  API_POST_UPDATE_LIMIT_STATUS
} from '../../../utils/APIUrls';
import Hand from '../../../assets/images/hand.png';
import Block from '../../../assets/images/block.png';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { POST } from '../../../utils/webAPI.service';
import ReactTable from 'react-table';
import 'react-datepicker/dist/react-datepicker.css';
import { applicationColumns } from './offer.config';
import HistoryIcon from '../../../assets/images/history.png';
import OfferHistoryModal from './offerHistoryModal';
import UnlockLimitModal from './unlockLimitModal';
import CredoModal from '../../../components/CredoModal';
import { getActiveLoan } from './offer.actions';
import Button from '../../../components/Button/index';
import { loaderModal } from '../../../store/actions';

class Offer extends Component {
  state = {
    product: null,
    setupFees: null,
    limits: false,
    activeButton: true,
    isModalOpen: false
  };
  componentDidMount = async () => {
    let { actions, match } = this.props,
      { loanId } = match.params,
      token = localStorage.getItem('token');
    actions.loaderModal(true);
    if (token) {
      await actions.getActiveLoan(loanId);
      actions.loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }
  };
  changeOfferStatus = limitStatus => {
    let { activeLoans, match } = this.props,
      { userId } = match.params,
      { offerId } = activeLoans,
      dataToSend = {
        limitStatus,
        offerId,
        userId
      };
    POST(API_POST_UPDATE_LIMIT_STATUS, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Status updated successfully');
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };

  toggleModal = (modalType, modalName) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      modalName
    });
  };

  submitOffer = e => {
    e.preventDefault();
    let { activeLoans, match } = this.props,
      { loanId } = match.params,
      { prodName, programName } = activeLoans,
      {
        extension,
        gracePeriod,
        monthlyInterestRate,
        extensionInterestRate,
        setupFees,
        tenure
      } = this.state,
      data = {
        extension,
        gracePeriod,
        monthlyInterestRate,
        extensionInterestRate,
        setupFees,
        tenure,
        programName,
        prodName,
        loanId
      };

    POST(API_POST_OFFER_ACTIVE, data)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Offer Activated And Sent Successfully');
          let { actions, match } = this.props,
            { loanId } = match.params;
          actions.getActiveLoan(loanId);
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let {
        activeLoans,
        promoterData,
        match,
        loanLimits,
        allOffers,
        regenerateCheck
      } = this.props,
      { userId } = match.params,
      { isModalOpen, tenure, modalType, modalName } = this.state,
      applicationStatus = activeLoans?.status?.toLowerCase();

    return (
      <>
        <div className="offer_wrapper container">
          <Helmet>
            <meta charSet="utf-8" />
            <title> Offer</title>
          </Helmet>

          {applicationStatus === 'pending' ? (
            <>
              <div className="card">
                <div className="row ">
                  <h3 className="col-md-10 card_heading_secondary m-heading">
                    Activate Offer
                  </h3>
                  <h5
                    className="col-md-2  add_button"
                    onClick={() => this.toggleModal(OfferHistoryModal)}
                  >
                    <img src={HistoryIcon} alt="icon" />
                    <span>Offer History</span>
                  </h5>
                </div>
                <div className="row generic_button_box">
                  <div className="col-md-2 text-grey pl-0">
                    Entity Name
                    <div className="text-bold">
                      {promoterData?.entityName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 text-grey">
                    User ID
                    <div className="text-bold">{userId}</div>
                  </div>
                  <div className="col-md-2 text-grey">
                    Offer ID
                    <div className="text-bold">{activeLoans?.offerId}</div>
                  </div>
                </div>
                <form onSubmit={e => this.submitOffer(e)}>
                  <div className="row pl-4">
                    <div className="col-md-3 pl-0">
                      <div className=" text-grey">
                        Product scheme
                        <input
                          disabled={true}
                          placeholder="Product Scheme"
                          value={activeLoans?.prodName}
                          className={'not_allowed'}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className=" text-grey">
                        Program
                        <input
                          disabled={true}
                          className={'not_allowed'}
                          placeholder=""
                          value={activeLoans?.programName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pl-4">
                    <div className="col-md-3 pl-0">
                      <div className=" text-grey">
                        Sanction Limit
                        <input
                          className={'not_allowed'}
                          disabled={true}
                          placeholder="Enter max limit"
                          value={activeLoans?.offerLimitAmt}
                        />
                      </div>
                    </div>
                    <div className="col-md-3" />
                  </div>

                  <div className="row pl-4">
                    <div className="col-md-3 pl-0">
                      <div className=" text-grey">
                        Standard tenure
                        <input
                          min={loanLimits?.minTenure}
                          max={loanLimits?.maxTenure}
                          placeholder=" Enter Days"
                          value={tenure}
                          type="number"
                          required
                          onChange={e =>
                            this.setState({
                              tenure: e.target.value
                            })
                          }
                        />
                        <div className="limit-parameters">
                          Between {loanLimits?.minTenure}-{
                            loanLimits?.maxTenure
                          }{' '}
                          days
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className=" text-grey">
                        Extension
                        <input
                          max={loanLimits?.extension}
                          required
                          placeholder="Enter Days"
                          value={this.state.extension}
                          type="number"
                          onChange={e =>
                            this.setState({ extension: e.target.value })
                          }
                        />
                        <div className="limit-parameters">
                          Upto {loanLimits?.extension} days
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className=" text-grey">
                        Grace Period
                        <input
                          placeholder=" Enter Days"
                          value={this.state.gracePeriod}
                          required
                          max={loanLimits?.gracePeriodDays}
                          type="number"
                          onChange={e =>
                            this.setState({ gracePeriod: e.target.value })
                          }
                        />
                        <div className="limit-parameters">
                          Upto {loanLimits?.gracePeriodDays} days
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pl-4">
                    <div className="col-md-3 pl-0">
                      <div className=" text-grey">
                        Monthly Interest Rate
                        <input
                          max={loanLimits?.maxIntRate}
                          min={loanLimits?.minIntRate}
                          step={0.1}
                          placeholder="Enter Monthly Interest Rate"
                          type="number"
                          required
                          value={this.state.monthlyInterestRate}
                          onChange={e =>
                            this.setState({
                              monthlyInterestRate: e.target.value
                            })
                          }
                        />
                        <div className="limit-parameters">
                          Between {loanLimits?.minIntRate}% -{' '}
                          {loanLimits?.maxIntRate}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className=" text-grey">
                        Extension Interest Rate
                        <input
                          max={loanLimits?.extensionInterestRate}
                          placeholder=" Enter Extension Interest Rate"
                          type="number"
                          required
                          step={0.1}
                          value={this.state.extensionInterestRate}
                          onChange={e =>
                            this.setState({
                              extensionInterestRate: e.target.value
                            })
                          }
                        />
                        <div className="limit-parameters">
                          Upto {loanLimits?.extensionInterestRate}%{' '}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className=" text-grey">
                        Setup Fees
                        <input
                          max={loanLimits?.setupFees}
                          placeholder="Enter Setup Fee "
                          value={this.state.setupFees}
                          type="number"
                          required
                          onChange={e =>
                            this.setState({ setupFees: e.target.value })
                          }
                        />
                        <div className="limit-parameters">
                          Upto ₹ {loanLimits?.setupFees}{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <button className="submit_button" type="submit">
                      Activate Offer
                    </button>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <h3 className="card_heading">Offer History</h3>
                    <ReactTable
                      data={allOffers}
                      columns={applicationColumns}
                      defaultPageSize={10}
                      resizable={false}
                      showPagination={false}
                      minRows={0}
                      getTrProps={this.rowClicked}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : applicationStatus === 'approved' ? (
            <>
              <div className="card">
                <div className="row ">
                  <h3 className="col-md-10 card_heading_secondary m-heading">
                    Activate Offer
                  </h3>
                  <h5
                    className="col-md-2  add_button"
                    onClick={() => this.toggleModal(OfferHistoryModal)}
                  >
                    <img src={HistoryIcon} alt="icon" />
                    <span>Offer History</span>
                  </h5>
                </div>
                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Entity Name
                    <div className="text-bold">
                      {promoterData?.entityName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    User ID
                    <div className="text-bold">{userId ?? '-'}</div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Offer ID
                    <div className="text-bold">
                      {activeLoans?.offerId ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Product Scheme
                    <div className="text-bold">
                      {activeLoans?.prodName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Program
                    <div className="text-bold">
                      {activeLoans?.programName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Status
                    <div className="text-bold">
                      {activeLoans?.status ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="row main-row">
                  <div className="col-md-2 main-row_inner">
                    Santion Limit
                    <div className="text-bold">
                      ₹ {rupeeDecimalFormatter(activeLoans?.offerLimitAmt)}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Sanction Date
                    <div className="text-bold">
                      {activeLoans?.sanctionDate ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Standard tenure
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(activeLoans?.tenure)}   Days`}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Extension
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(
                        activeLoans?.extension
                      )}   Days`}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Grace Period
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(
                        activeLoans?.gracePeriod
                      )}   Days`}
                    </div>
                  </div>
                </div>

                <div className="row main-row">
                  <div className="col-md-2 main-row_inner">
                    Monthly Interest Rate
                    <div className="text-bold">
                      {activeLoans?.monthlyInterestRate ?? '-'}
                      %
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Extension Interest Rate
                    <div className="text-bold">
                      {activeLoans?.extensionInterestRate ?? '-'}
                      %
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Setup Fees
                    <div className="text-bold">
                      ₹ {rupeeDecimalFormatter(activeLoans?.setupFees)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <h3 className="card_heading">Offer History</h3>
                    <ReactTable
                      data={allOffers}
                      columns={applicationColumns}
                      defaultPageSize={10}
                      resizable={false}
                      showPagination={false}
                      minRows={0}
                      getTrProps={this.rowClicked}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : applicationStatus === 'accepted' ? (
            <>
              <div className="card">
                <div className="row ">
                  <h3 className="col-md-10 card_heading_secondary m-heading">
                    Activate Offer
                  </h3>
                  <h5
                    className="col-md-2  add_button"
                    onClick={() => this.toggleModal(OfferHistoryModal)}
                  >
                    <img src={HistoryIcon} alt="icon" />
                    <span>Offer History</span>
                  </h5>
                </div>
                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Entity Name
                    <div className="text-bold">
                      {promoterData?.entityName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    User ID
                    <div className="text-bold">{userId ?? '-'}</div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Offer ID
                    <div className="text-bold">
                      {' '}
                      {activeLoans?.offerId ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Product Scheme
                    <div className="text-bold">
                      {activeLoans?.prodName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Program
                    <div className="text-bold">
                      {activeLoans?.programName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className=" text-grey">
                      Status
                      <div className="text-bold">
                        {activeLoans?.status ?? '-'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="limit_button" />
                    <Button
                      variant="contained"
                      disabled={!regenerateCheck}
                      onClick={() =>
                        this.toggleModal(UnlockLimitModal, 'UnlockLimitModal')
                      }
                    >
                      Regenerate
                    </Button>
                  </div>
                </div>

                <div className="row main-row">
                  <div className="col-md-2 main-row_inner">
                    Santion Limit
                    <div className="text-bold">
                      ₹ {rupeeDecimalFormatter(activeLoans?.offerLimitAmt)}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Sanction Date
                    <div className="text-bold">
                      {activeLoans?.sanctionDate ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Standard tenure
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(activeLoans?.tenure)}   Days`}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Extension
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(
                        activeLoans?.extension
                      )}   Days`}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Grace Period
                    <div className="text-bold">
                      {`${rupeeDecimalFormatter(
                        activeLoans?.gracePeriod
                      )}   Days`}
                    </div>
                  </div>
                </div>

                <div className="row main-row">
                  <div className="col-md-2 main-row_inner">
                    Monthly Interest Rate
                    <div className="text-bold">
                      {activeLoans?.monthlyInterestRate ?? '-'} %
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Extension Interest Rate
                    <div className="text-bold">
                      {activeLoans?.extensionInterestRate ?? '-'} %
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    Setup Fees
                    <div className="text-bold">
                      ₹ {rupeeDecimalFormatter(activeLoans?.setupFees)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <h3 className="card_heading">Limit Servicing</h3>
                    <div className="row my-4">
                      <div
                        className="col-md-2 limit_button hold-button"
                        onClick={() => this.changeOfferStatus('On Hold')}
                      >
                        <img src={Hand} alt=" Hand Icon" />
                        Hold
                      </div>
                      <div
                        className="col-md-2 limit_button block-button"
                        onClick={() => this.changeOfferStatus('Blocked')}
                      >
                        <img src={Block} alt="Block Icon" />
                        Block
                      </div>
                      <div
                        className="col-md-3 limit_button re_active"
                        onClick={() => this.changeOfferStatus('Active')}
                      >
                        Reactivate Limit
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <input
                          placeholder=" Enter revised amount"
                          className="mt-0"
                        />
                      </div>
                      <div className="col-md-3 limit_button revise_button">
                        Revise Limit{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mt-0">
                    <h3 className="card_heading">Offer History</h3>
                    <ReactTable
                      data={allOffers}
                      columns={applicationColumns}
                      defaultPageSize={10}
                      resizable={false}
                      showPagination={false}
                      minRows={0}
                      getTrProps={this.rowClicked}
                    />
                  </div>
                </div>
              </div>

              <div className="close_button">
                <div className="close_button_inner">Close Limit</div>
              </div>
            </>
          ) : (
            <>
              <div className="card">
                <div className="row ">
                  <h3 className="col-md-10 card_heading_secondary m-heading">
                    Activate Offer
                  </h3>
                  <h5
                    className="col-md-2  add_button"
                    onClick={() => this.toggleModal(OfferHistoryModal)}
                  >
                    <img src={HistoryIcon} alt="icon" />
                    <span>Offer History</span>
                  </h5>
                </div>
                <div className="row main-row border-grey ">
                  <div className="col-md-2 main-row_inner">
                    Entity Name
                    <div className="text-bold">
                      {promoterData?.entityName ?? '-'}
                    </div>
                  </div>
                  <div className="col-md-2 main-row_inner">
                    User ID
                    <div className="text-bold">{userId ?? '-'}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card mt-0">
                    <h3 className="card_heading">Offer History</h3>
                    <ReactTable
                      data={allOffers}
                      columns={applicationColumns}
                      defaultPageSize={10}
                      resizable={false}
                      showPagination={false}
                      minRows={3}
                      getTrProps={this.rowClicked}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <CredoModal
            isOpen={isModalOpen}
            styles={
              modalName === 'UnlockLimitModal'
                ? {
                    content: {
                      minWidth: '30vw',
                      margin: '5rem auto',
                      height: '30vh'
                    }
                  }
                : {
                    content: {
                      minWidth: '70vw',
                      margin: '5rem auto',
                      height: '80vh'
                    }
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            stateData={this.state}
            propsData={this.props}
          />
        </div>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getActiveLoan,
      loaderModal
    },
    dispatch
  )
});

const mapStateToProps = ({ offerReducer, leadManagementNavsReducer }) => ({
  activeLoans: offerReducer.activeLoans,
  promoterData: leadManagementNavsReducer.promoterData,
  loanLimits: offerReducer.loanLimits,
  allOffers: offerReducer.allOffers,
  offerHistory: offerReducer.offerHistory,
  regenerateCheck: offerReducer.regenerateCheck
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);

import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import UnitLocation from '../../../assets/images/UnitLocation.svg';

export default class ImagesModal extends Component {
  state = { index: 0 };

  handleSelect = (index, e) => {
    this.setState({ index });
  };

  render() {
    let { propsData } = this.props,
      { photos } = propsData,
      { businessDocuments } = photos,
      { index } = this.state;

    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'> Business Photos </h3>
        <Carousel activeIndex={index} onSelect={this.handleSelect} interval={0}>
          {businessDocuments?.map((image, index) => {
            let { fileUrl, latitude, longtitude, name } = image;
            return (
              <Carousel.Item className='carousel_item'>
                <img className='d-block ' src={fileUrl} alt='First slide' />
                <Carousel.Caption>
                  <h3>
                    {index + 1}.{name}
                  </h3>
                  <p>
                    <img
                      src={UnitLocation}
                      alt='location'
                      className='location_icon'
                    />{' '}
                    Latitude : {latitude}&nbsp;&nbsp; Longtitude :{longtitude}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div></div>
      </div>
    );
  }
}

import React, { Component } from 'react';

export default class UserProfileModal extends Component {
  render() {
    let { propsData } = this.props,
      { photos, promoterData } = propsData;
    return (
      <div className='compare-details-wrapper'>
        <h3 className='card_heading'>{promoterData?.fullName}</h3>
        <img
          src={photos?.profileDocument?.fileUrl}
          alt='user_profile'
          className='profile_img'
        />
      </div>
    );
  }
}

const SET_BUSINESS_DETAILS = Symbol('setBusinessDetails'),
  SET_ADDRESS_DETAILS = Symbol('setAddressDetails'),
  SET_BANK_DETAILS = Symbol('setBankDetails'),
  SET_DEVICE_DETAILS = Symbol('setDeviceDetails'),
  SET_COMPARE_DETAILS = Symbol('setComapreDetails'),
  SET_PHOTOS_DETAILS = Symbol('setPhotosDetails'),
  SET_CONDITION_NOTES = Symbol('setConditionNotes'),
  SET_LOAN_LIMIT = Symbol('setLoanLimit'),
  SET_PROGRAMS = Symbol('setPrograms'),
  SET_COMMENT_NOTES = Symbol('setCommentNotes'),
  SET_TRANSACTION_DETAILS = Symbol('setTransactionDetails'),
  SET_DEDUPE_DETAILS = Symbol('setDedupeDetails');

export {
  SET_BUSINESS_DETAILS,
  SET_ADDRESS_DETAILS,
  SET_BANK_DETAILS,
  SET_DEVICE_DETAILS,
  SET_COMPARE_DETAILS,
  SET_PHOTOS_DETAILS,
  SET_CONDITION_NOTES,
  SET_COMMENT_NOTES,
  SET_LOAN_LIMIT,
  SET_PROGRAMS,
  SET_TRANSACTION_DETAILS,
  SET_DEDUPE_DETAILS
};

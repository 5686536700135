import { GET, POST } from '../../../utils/webAPI.service';
import {
  API_GET_BUSINESS_DETAILS,
  API_GET_ADDRESS,
  API_GET_PHOTOS,
  API_GET_COMAPRE_DETAILS,
  API_GET_BANK_DETAILS,
  API_GET_DEVICE_DETAILS,
  API_GET_NOTES,
  API_GET_PROGRAMS,
  API_GET_TRANSACTION_ANALYSIS,
  API_POST_DEDUPE_STATUS
} from '../../../utils/APIUrls';
import * as applicationConstants from './application.actions.constants';

const getBusinessDetails = (loanId, userId) => {
  return dispatch => {
    return GET(API_GET_BUSINESS_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_BUSINESS_DETAILS,
              data: data.data
            });
          }
          return data;
        }
      })
      .then(data => {
        if (data) {
          //once we get data from above api
          let { productName, mobileNumber } = data?.data;
          if (data.code === 200) {
            GET(API_GET_PROGRAMS(productName))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: applicationConstants.SET_PROGRAMS,
                      data: data.data
                    });
                  }
                }
                return data;
              })
              .catch(err => {
                throw err;
              });

            GET(API_GET_DEVICE_DETAILS(mobileNumber))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: applicationConstants.SET_DEVICE_DETAILS,
                      data: data.data
                    });
                  }
                }
                return data;
              })
              .then(({ data }) => {
                // dedupe api call once we get the imei numbe
                if (data) {
                  let { imeiNumber } = data;
                  POST(API_POST_DEDUPE_STATUS, { userId, imeiNumber }).then(
                    ({ data }) => {
                      if (data.code === 200) {
                        if (data.data) {
                          dispatch({
                            type: applicationConstants.SET_DEDUPE_DETAILS,
                            data: data.data
                          });
                        }
                      }
                      return data;
                    }
                  );
                }
              })
              .catch(err => {
                throw err;
              });

            GET(API_GET_TRANSACTION_ANALYSIS(loanId, mobileNumber))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: applicationConstants.SET_TRANSACTION_DETAILS,
                      data: data.data
                    });
                  }
                }
                return data;
              })
              .catch(err => {
                throw err;
              });
          }
        }
      })
      .catch(err => {
        throw err;
      });
  };
};

const getPhotos = loanId => {
  return dispatch => {
    return GET(API_GET_PHOTOS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_PHOTOS_DETAILS,
              data: data.data
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getAddressDetails = loanId => {
  return dispatch => {
    return GET(API_GET_ADDRESS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_ADDRESS_DETAILS,
              data: data.data
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getCompareDetails = loanId => {
  return dispatch => {
    return GET(API_GET_COMAPRE_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_COMPARE_DETAILS,
              data: data.data
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getBankDetails = userId => {
  return dispatch => {
    return GET(API_GET_BANK_DETAILS(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_BANK_DETAILS,
              data: data.data
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getCommentNotes = loanId => {
  return dispatch => {
    return GET(API_GET_NOTES(loanId, 'comment'))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_COMMENT_NOTES,
              data: data.data.comment
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};
const getConditionNotes = loanId => {
  return dispatch => {
    return GET(API_GET_NOTES(loanId, 'condition'))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_CONDITION_NOTES,
              data: data.data.comment
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

export {
  getBusinessDetails,
  getAddressDetails,
  getCompareDetails,
  getConditionNotes,
  getCommentNotes,
  getBankDetails,
  getPhotos
};

import React, { Component } from 'react';
import {
  API_GET_VERIFIED_SUPPLIERS,
  API_POST_CONTACT,
  API_POST_UPLOAD_INVOICE
} from '../../../utils/APIUrls';
import { GET, POST } from '../../../utils/webAPI.service';
import DatePicker from '../../../components/DatePicker';
import { getDMY } from '../../../utils/utility';
import User from '../../../assets/images/add-user.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInvoices, getLimitSummary } from './invoices.actions';

class SingleInvoiceModal extends Component {
  state = {
    suppliersList: [],
    supplierFlag: false,
    isNewSupplier: 'false',
    activeFigure: 'bank_transfer',
    isButtonDisabled: false
  };

  componentDidMount() {
    let { propsData } = this.props;
    this.fetchSuppliersList(propsData?.match?.params?.userId);
  }

  fetchSuppliersList = userId => {
    GET(API_GET_VERIFIED_SUPPLIERS(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({
            suppliersList: data?.data?.suppliersList
          });
        } else if (data.code === 999) {
          alert(data.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        if (err?.response?.status === 406) {
          alert(err?.response?.data?.errors[0]);
        } else {
          alert('Something went wrong');
        }
        throw err;
      });
  };

  paymentMethodHandler = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === 'newSupplier') {
      this.setState({
        [name]: value,
        supplierFlag: false,
        invoiceAmount: null,
        invoiceDate: null,
        invoiceNumber: null,
        supplierName: null,
        mobileNumber: null
      });
    } else if (type === 'existingSupplier') {
      this.setState({
        [name]: value,
        invoiceAmount: null,
        invoiceDate: null,
        invoiceNumber: null
      });
    }
  };

  dateHandler = e => {
    if (e !== null) {
      let invoiceDate = getDMY(e);
      this.setState({ invoiceDate });
    }
  };

  approveHandler = async e => {
    let { suppliersList } = this.state,
      { propsData } = this.props;
    const mobileNumber = e.target.value;
    const selectedSupplier = suppliersList.find(
      supplier => supplier.mobileNumber === mobileNumber
    );

    await this.setState({
      supplierName: selectedSupplier.name,
      mobileNumber: selectedSupplier.mobileNumber
    });

    let dataToSend = {
      userId: propsData?.match?.params?.userId,
      mobileNumber: this.state.mobileNumber,
      contactType: 'supplier'
    };

    POST(API_POST_CONTACT, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({ contactDetails: data?.data, supplierFlag: true });
        } else if (data.code === 999) {
          alert(data.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        if (err?.response?.status === 406) {
          alert(err?.response?.data?.errors[0]);
        } else {
          alert('Something went wrong');
        }
      });
  };

  uploadInvoiceHandler = e => {
    e.preventDefault();
    this.setState({
      isButtonDisabled: true
    });
    let {
      invoiceAmount,
      invoiceNumber,
      paymentMethod,
      invoiceDate,
      isNewSupplier,
      supplierName,
      mobileNumber,
      activeFigure,
      accountNumber,
      ifsc,
      confirmAccountNumber,
      upiId
    } = this.state;
    if (confirmAccountNumber !== accountNumber) {
      alert('Account number does not match, please re-enter');
      return 0;
    }

    let { propsData } = this.props,
      paymentDetailsRequestCopy =
        activeFigure === 'upi'
          ? {
              bankDetails: {},
              upiDetails: {
                upiId
              }
            }
          : {
              bankDetails: {
                accountNumber,
                ifsc
              },
              upiDetails: {}
            };
    let dataToSend =
      isNewSupplier === 'true'
        ? {
            userId: propsData?.match?.params?.userId,
            offerId: propsData?.activeLoans?.offerId,
            invoiceDate,
            invoiceNumber,
            invoiceAmount,
            isNewSupplier: true,
            paymentMethod: activeFigure,
            supplierName,
            mobileNumber,
            paymentDetailsRequest: paymentDetailsRequestCopy
          }
        : {
            userId: propsData?.match?.params?.userId,
            offerId: propsData?.activeLoans?.offerId,
            invoiceDate,
            invoiceNumber,
            invoiceAmount,
            isNewSupplier: false,
            paymentMethod,
            supplierName,
            mobileNumber,
            paymentDetailsRequest: null
          };

    POST(API_POST_UPLOAD_INVOICE, dataToSend)
      .then(({ data }) => {
        this.setState({
          isButtonDisabled: false
        });
        if (data.code === 200) {
          alert('Invoice uploaded successfully');
          let { toggleModal, actions } = this.props,
            { getInvoices, getLimitSummary } = actions;
          toggleModal(SingleInvoiceModal);
          getLimitSummary(propsData?.match?.params?.userId);
          getInvoices(propsData?.match?.params?.userId, false, 1);
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch(err => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let {
        suppliersList,
        contactDetails,
        supplierFlag,
        isNewSupplier,
        invoiceNumber,
        invoiceAmount,
        activeFigure,
        supplierName,
        mobileNumber,
        accountNumber,
        confirmAccountNumber,
        ifsc,
        upiId,
        invoiceDate
      } = this.state,
      paymentMethodList = [{ mode: 'bank_transfer' }, { mode: 'upi' }];
    let dayOffSet = 10;
    let todayDate = new Date();
    let requiredMaxDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() + dayOffSet
    );
    let requiredMinDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() - dayOffSet
    );
    return (
      <div className="modal-wrapper">
        <h3 className="card_heading">Single Invoice Upload</h3>
        <div className=" card_heading_sub">Supplier Details</div>
        <div className="row">
          <div className="col-md-4">
            <label className="radio_button-label">
              <input
                type="radio"
                name="isNewSupplier"
                value={false}
                className="radio_button"
                onChange={e => this.handleChange(e, 'existingSupplier')}
                defaultChecked
              />
              Existing Supplier
            </label>
          </div>
          <div className="col-md-4">
            <label className="radio_button-label">
              <input
                type="radio"
                name="isNewSupplier"
                value={true}
                onChange={e => this.handleChange(e, 'newSupplier')}
                className="radio_button"
              />
              <img src={User} alt="user logo" className="user_image mr-2" />
              Add new Supplier
            </label>
          </div>
        </div>
        <form onSubmit={e => this.uploadInvoiceHandler(e)}>
          <div>
            <div className="row">
              <div className="col-md-4">
                {isNewSupplier === 'false' ? (
                  <select
                    onChange={this.approveHandler}
                    name="supplier"
                    required
                    value={this.state.supplier}
                  >
                    <option disabled selected>
                      Choose Supplier Name
                    </option>
                    {suppliersList?.map((item, index) => {
                      return (
                        <option
                          key={`supplier-${index}`}
                          value={item.mobileNumber}
                        >
                          {' '}
                          {item.name}{' '}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
              </div>
              {supplierFlag === true ? (
                <div className="col-md-4">
                  <select
                    onChange={this.paymentMethodHandler}
                    name="paymentMethod"
                    required
                    value={this.state.paymentMethod}
                  >
                    <option value="">Choose Payment Method</option>
                    {paymentMethodList?.map((e, index) => {
                      return (
                        <option key={`mode-${index}`} value={e.mode}>
                          {' '}
                          {e.mode}{' '}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
            </div>
            {supplierFlag === true ? (
              <>
                <div className="card_heading_sub">Invoice Details</div>
                <div className="row">
                  <div className="col-md-4">
                    <div className=" input_heading">
                      Invoice Number
                      <input
                        className={'not_allowed'}
                        value={invoiceNumber}
                        required
                        onChange={e =>
                          this.setState({
                            invoiceNumber: e.target.value
                          })
                        }
                        placeholder="Enter the Invoice Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" input_heading">
                      Invoice Date
                      <DatePicker
                        placeholderText={'Enter the Invoice Date'}
                        required={true}
                        defaultValue={null}
                        name={'invoiceDate'}
                        maxDate={requiredMaxDate}
                        minDate={requiredMinDate}
                        selected={
                          invoiceDate
                            ? new Date(invoiceDate.split('/').reverse())
                            : null
                        }
                        onChange={e => this.dateHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_heading">
                      Invoice Amount (in Gst)
                      <input
                        className={'not_allowed'}
                        required
                        onChange={e =>
                          this.setState({
                            invoiceAmount: e.target.value
                          })
                        }
                        type="number"
                        placeholder="Enter the Invoice Amount(in Gst)"
                        value={invoiceAmount}
                      />
                    </div>
                  </div>
                </div>
                <div className="card_heading_sub ">Payment Details</div>
                <div className="row">
                  {contactDetails?.paymentAccountDetails?.banksList?.map(e => {
                    return (
                      <div className="col-md-5 small-card">
                        <div className="row">
                          <div className="col-md-12 text-grey m-1">
                            Account Number
                            <div className="text-bold">
                              {e?.accountNumber || '-'}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-5 text-grey m-1">
                            Mobile Number
                            <div className="text-bold">
                              {contactDetails?.mobileNumber || '-'}
                            </div>
                          </div>
                          <div className="col-md-5 text-grey m-1">
                            IFSC Code
                            <div className="text-bold">
                              {e?.ifscCode || '-'}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 text-grey m-1">
                            Bank Name
                            <div className="text-bold">
                              {e?.bankName || '-'}
                            </div>
                          </div>
                          <div className="col-md-5 text-grey m-1">
                            Bank Branch
                            <div className="text-bold">
                              {' '}
                              {e?.branchName || '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {contactDetails?.paymentAccountDetails?.upiList?.map(e => {
                    return (
                      <div className="col-md-5 small-card">
                        <div className="row">
                          <div className="col-md-5 text-grey ">
                            Mobile Number
                            <div className="text-bold">
                              {contactDetails?.mobileNumber || '-'}
                            </div>
                          </div>
                          <div className="col-md-5 text-grey">
                            UPI Handle
                            <div className="text-bold">{e?.upiId || '-'}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="save_button">
                  <button
                    className="basic-button  button-success"
                    type="submit"
                    disabled={this.state.isButtonDisabled}
                  >
                    Upload
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </form>

        {/* New Supplier Html */}
        {isNewSupplier === 'true' ? (
          <>
            <form onSubmit={e => this.uploadInvoiceHandler(e)}>
              <div className="row">
                <div className="col-md-4">
                  <div className=" input_heading">
                    Supplier Name
                    <input
                      className={'not_allowed'}
                      value={supplierName}
                      required
                      onChange={e =>
                        this.setState({
                          supplierName: e.target.value
                        })
                      }
                      placeholder="Enter the  Supplier Name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className=" input_heading">
                    Supplier Mobile Number
                    <input
                      className={'not_allowed'}
                      value={mobileNumber}
                      required
                      maxLength="10"
                      type="tel"
                      onChange={e =>
                        this.setState({
                          mobileNumber: e.target.value
                        })
                      }
                      placeholder="Enter the Supplier Mobile  Number"
                    />
                  </div>
                </div>
              </div>

              <div className=" card_heading_sub">Invoice Details</div>
              <div className="row">
                <div className="col-md-4">
                  <div className=" input_heading">
                    Invoice Number
                    <input
                      className={'not_allowed'}
                      value={invoiceNumber}
                      required
                      onChange={e =>
                        this.setState({
                          invoiceNumber: e.target.value
                        })
                      }
                      placeholder="Enter the Invoice Number"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className=" input_heading">
                    Invoice Date
                    <DatePicker
                      placeholderText={'Enter the Invoice Date'}
                      required={true}
                      name={'invoiceDate'}
                      maxDate={new Date()}
                      selected={
                        this.state.invoiceDate
                          ? new Date(
                              this.state.invoiceDate.split('/').reverse()
                            )
                          : null
                      }
                      onChange={e => this.dateHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_heading">
                    Invoice Amount (in Gst)
                    <input
                      className={'not_allowed'}
                      required
                      onChange={e =>
                        this.setState({
                          invoiceAmount: e.target.value
                        })
                      }
                      type="number"
                      placeholder="Enter the Invoice Amount(in Gst)"
                      value={invoiceAmount}
                    />
                  </div>
                </div>
              </div>
              <div className="card_heading_sub">Party Payment Details</div>
              <div className="row toggle_button_box">
                <div className="col-md-3 side_title">Select Payment Mode:</div>

                <div
                  onClick={() => {
                    this.setState({
                      activeFigure: 'bank_transfer',
                      upiId: null
                    });
                  }}
                  className={
                    activeFigure === 'bank_transfer'
                      ? 'col-md-2 toggle_button_active'
                      : 'col-md-2 toggle_button'
                  }
                >
                  Bank Details
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      activeFigure: 'upi',
                      accountNumber: null,
                      ifsc: null,
                      confirmAccountNumber: null
                    });
                  }}
                  className={
                    activeFigure === 'upi'
                      ? 'col-md-2 toggle_button_active'
                      : 'col-md-2 toggle_button'
                  }
                >
                  UPI ID
                </div>
              </div>

              {activeFigure === 'bank_transfer' ? (
                <div className="row">
                  <div className="col-md-4">
                    <div className=" input_heading">
                      Bank account number
                      <input
                        className={'not_allowed'}
                        value={accountNumber}
                        required
                        onChange={e =>
                          this.setState({
                            accountNumber: e.target.value
                          })
                        }
                        onPaste={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCut={e => {
                          e.preventDefault();
                          return false;
                        }}
                        placeholder="Enter the Bank account number"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" input_heading">
                      Bank account number
                      <input
                        className={'not_allowed'}
                        value={confirmAccountNumber}
                        required
                        onChange={e =>
                          this.setState({
                            confirmAccountNumber: e.target.value
                          })
                        }
                        onPaste={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCut={e => {
                          e.preventDefault();
                          return false;
                        }}
                        placeholder="Re-enter the Bank account number"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_heading">
                      IFSC code
                      <input
                        className={'not_allowed'}
                        required
                        onChange={e =>
                          this.setState({
                            ifsc: e.target.value
                          })
                        }
                        placeholder="Enter the IFSC code"
                        value={ifsc}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {activeFigure === 'upi' ? (
                <div className="row">
                  <div className="col-md-4">
                    <div className=" input_heading">
                      Supplier UPI ID
                      <input
                        className={'not_allowed'}
                        value={upiId}
                        required
                        onChange={e =>
                          this.setState({
                            upiId: e.target.value
                          })
                        }
                        placeholder="Enter the Supplier UPI ID"
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="save_button">
                <button
                  className="basic-button  button-success"
                  type="submit"
                  disabled={this.state.isButtonDisabled}
                >
                  Upload
                </button>
              </div>
            </form>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getInvoices,
      getLimitSummary
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleInvoiceModal);

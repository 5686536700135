import { GET } from '../../../utils/webAPI.service';
import { API_GET_BUSINESS_DETAILS } from '../../../utils/APIUrls';
import * as leadManagementNavsConstants from './LeadManagementNavs.actions.constants';

const getPromoterDetails = loanId => {
  return dispatch => {
    return GET(API_GET_BUSINESS_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: leadManagementNavsConstants.SET_PROMOTER_DATA,
              data: data.data
            });
          }
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

export { getPromoterDetails };
